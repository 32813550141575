import { doFetchCategoryList } from "@/services/header.service";
import { getField, updateField } from "vuex-map-fields";
import humps from "lodash-humps";

export const headerCategoryList = {
  namespaced: true,
  state: {
    inProgress: false,
    categoryList: {},
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,

    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo;
    },
    setCategoryList(state, list) {
      state.categoryList = list;
    },
  },
  actions: {
    fetchCategoryList: async ({ commit }) => {
      const categoryList = await doFetchCategoryList();
      if (categoryList) {
        commit("setCategoryList", humps(categoryList));
      }
    },
  },
};
