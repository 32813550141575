/* eslint-disable */
import {
  doFetchFonts,
  doLookupFonts,
  doFetchOne as doFetchProductOne,
  doFetchProductOneBySku,
  doFetchPaper,
  doLookupPaper,
  doFetchMotifs,
  doLookupMotifs,
  doFetchMonograms,
  doLookupMonograms,
  doFetchLiners,
  doFetchImages,
  doFetchColors,
  doPostCloneProduct,
  doPostCloneProductParts,
} from "@/services/designer.service";
import { getField, updateField } from "vuex-map-fields";
import humps from "lodash-humps";
import cloneDeep from "lodash/cloneDeep";
import createHumps from "lodash-humps/lib/createHumps";
import { snakeCase } from "lodash";
const snakes = createHumps(snakeCase);

export const designerOne = {
  namespaced: true,
  state: {
    inProgress: true,
    one: {},
    defaultProperties: {
      textFont: {
        fontOptions: [
          {
            name: "Arial",
            defaultValue: 12,
            fontMax: 12,
            fontMin: 12,
            letterSpacing: 1,
            lineHeight: 1.16,
            strokeWidth: 1,
          },
        ],
      },
      alignProperty: {
        defaultAlign: "left",
        alignOptions: [],
        anchorAlignment: "top",
      },
      print: { defaultPrintType: "Digital", printTypeOptions: [] },
      maxTextWidth: 250,
      maxTextLines: 1,
      lineHeight: 18,
      elementName: "",
      colorStyle: "",
      processStyle: "",
      fontStyle: "",
      top: "",
      left: "",
      width: 8.14,
      height: 10.66,
      paper: { paperOptions: [{ name: "Select Paper", url: "" }] },
      motif: { motifOptions: [{ name: "Select Motif" }] },
      monogram: {
        monogramOptions: [
          {
            name: "Select Monogram",
            pointSize: 12,
            lettersCount: 3,
            thumbnailUrl: "",
            id: "",
            url: "",
            otfUrl: "",
            buildString: "",
          },
        ],
      },
      //color:{name:"Navy", cid:"30"}
      color: { name: "Navy", cid: "30", colorHex: "#515a6d" },
    },
    font: { fontOptions: [] },
    fontList: [],
    paperList: [],
    motifList: [],
    monogramList: [],
    linersList: [],
    monogram: { monogramOptions: [] },
    paper: { paperOptions: [] },
    motif: { motifOptions: [] },
    part: { name: "", url: "" },
    defaultPart: { name: "", url: "" },
    parts: [],
    fontsInProgress: false,
    motifsInProgress: false,
    paperInProgress: false,
    monogramsInProgress: false,
    linersInProgress: false,
    imagesInProgress: false,
    linersTags: [],
    imageTags: [],
    colorList: [],
    colorTags: [],
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,

    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo;
    },
    fontsInProgress(state, yesOrNo) {
      state.fontsInProgress = yesOrNo;
    },
    setSelectedFontsDown(state, index) {
      let prev = state.font.fontOptions[index];
      let next = state.font.fontOptions[index + 1];
      if (index < state.font.fontOptions.length - 1) {
        state.font.fontOptions.splice(index, 1, next);
        state.font.fontOptions.splice(index + 1, 1, prev);
      }
    },
    setSelectedFontsUp(state, index) {
      let prev = state.font.fontOptions[index];
      let next = state.font.fontOptions[index - 1];
      if (index > 0) {
        state.font.fontOptions.splice(index, 1, next);
        state.font.fontOptions.splice(index - 1, 1, prev);
      }
    },

    setDefaultFonts(state) {
      let font = cloneDeep({
        id: "1",
        name: "Arial",
        defaultValue: 16,
        fontMax: 16,
        fontMin: 16,
        letterSpacing: 1,
        lineHeight: 1.16,
        strokeWidth: 1,
      });
      state.font.fontOptions.push(font);
    },
    removeFont(state, index) {
      if (state.font.fontOptions.length !== 1)
        state.font.fontOptions.splice(index, 1);
    },
    setSelectedFont(state, obj) {
      state.font.fontOptions[obj.index].id = obj.id;
      state.font.fontOptions[obj.index].name = obj.name;
      state.font.fontOptions[obj.index].url = obj.url;
      state.font.fontOptions[obj.index].strokeWidth = obj.strokeWidth;
    },
    setSelectedCustomFont(state, font) {
      state.font.fontOptions = font.fontOptions;
    },
    setFontList(state, list) {
      state.fontList = list;
    },
    setPaperList(state, list) {
      state.paperList = list;
    },
    setMotifList(state, list) {
      state.motifList = list;
    },
    setMonogramList(state, list) {
      state.monogramList = list;
    },
    setLinersList(state, list) {
      state.linersList = list;
    },
    setColorList(state, list) {
      state.colorList = list;
    },
    monogramsInProgress(state, yesOrNo) {
      state.monogramsInProgress = yesOrNo;
    },
    linersInProgress(state, yesOrNo) {
      state.linersInProgress = yesOrNo;
    },
    imagesInProgress(state, yesOrNo) {
      state.imagesInProgress = yesOrNo;
    },
    /*setMonogram(state, obj) {
            state.monogram.monogramOptions[obj.index] = obj.monogram;          
        },*/

    monogramSwapDown(state, index) {
      let prev = state.monogram.monogramOptions[index];
      let next = state.monogram.monogramOptions[index + 1];
      if (index < state.monogram.monogramOptions.length - 1) {
        state.monogram.monogramOptions.splice(index, 1, next);
        state.monogram.monogramOptions.splice(index + 1, 1, prev);
      }
    },
    monogramSwapUp(state, index) {
      let prev = state.monogram.monogramOptions[index];
      let next = state.monogram.monogramOptions[index - 1];
      if (index > 0) {
        state.monogram.monogramOptions.splice(index, 1, next);
        state.monogram.monogramOptions.splice(index - 1, 1, prev);
      }
    },
    addDefaultMonogram(state) {
      let monogram = cloneDeep({
        name: "Select Monogram",
        pointSize: 12,
        lettersCount: 3,
        thumbnailUrl: "",
        id: "",
        url: "",
        otfUrl: "",
        buildString: "",
      });
      state.monogram.monogramOptions.push(monogram);
    },
    removeMonogram(state, index) {
      if (state.monogram.monogramOptions.length !== 1)
        state.monogram.monogramOptions.splice(index, 1);
    },
    setSelectedMonogram(state, obj) {
      state.monogram.monogramOptions[obj.index].id = obj.id;
      state.monogram.monogramOptions[obj.index].name = obj.name;
      state.monogram.monogramOptions[obj.index].url = obj.url;
      state.monogram.monogramOptions[obj.index].pointSize = obj.pointSize;
      state.monogram.monogramOptions[obj.index].lettersCount = obj.lettersCount;
      state.monogram.monogramOptions[obj.index].thumbnailUrl = obj.thumbnailUrl;
      state.monogram.monogramOptions[obj.index].otfUrl = obj.otfUrl;
      state.monogram.monogramOptions[obj.index].buildString = obj.buildString;
    },
    setCustomMonogram(state, monogram) {
      state.monogram.monogramOptions = monogram.monogramOptions;
    },
    paperSwapDown(state, index) {
      let prev = state.paper.paperOptions[index];
      let next = state.paper.paperOptions[index + 1];
      if (index < state.paper.paperOptions.length - 1) {
        state.paper.paperOptions.splice(index, 1, next);
        state.paper.paperOptions.splice(index + 1, 1, prev);
      }
    },
    paperSwapUp(state, index) {
      let prev = state.paper.paperOptions[index];
      let next = state.paper.paperOptions[index - 1];
      if (index > 0) {
        state.paper.paperOptions.splice(index, 1, next);
        state.paper.paperOptions.splice(index - 1, 1, prev);
      }
    },

    addDefaultPaper(state) {
      let paper = cloneDeep({ name: "Select Paper", url: "" });
      state.paper.paperOptions.push(paper);
    },
    removePaper(state, index) {
      if (state.paper.paperOptions.length !== 1)
        state.paper.paperOptions.splice(index, 1);
    },
    setPaper(state, obj) {
      state.paper.paperOptions[obj.index] = obj.paper;
    },
    setCustomPaper(state, paper) {
      state.paper.paperOptions = paper.paperOptions;
    },
    setSurfaceToPaper(state, payload) {
      let allPapers = state.paper.paperOptions;
      allPapers.filter((paper) => {
        if (paper.id == payload.currentPaper.id) {
          paper.currentSelectedSurface = payload.surface;
        }
        return paper;
      });
      state.paper.paperOptions = [];
      state.paper.paperOptions = allPapers;
    },

    motifsInProgress(state, yesOrNo) {
      state.motifsInProgress = yesOrNo;
    },
    motifSwapDown(state, index) {
      let prev = state.motif.motifOptions[index];
      let next = state.motif.motifOptions[index + 1];
      if (index < state.motif.motifOptions.length - 1) {
        state.motif.motifOptions.splice(index, 1, next);
        state.motif.motifOptions.splice(index + 1, 1, prev);
      }
    },
    motifSwapUp(state, index) {
      let prev = state.motif.motifOptions[index];
      let next = state.motif.motifOptions[index - 1];
      if (index > 0) {
        state.motif.motifOptions.splice(index, 1, next);
        state.motif.motifOptions.splice(index - 1, 1, prev);
      }
    },

    addDefaultMotif(state) {
      let paper = cloneDeep({ name: "Select Motif", url: "" });
      state.motif.motifOptions.push(paper);
    },
    removeMotif(state, index) {
      if (state.motif.motifOptions.length !== 1)
        state.motif.motifOptions.splice(index, 1);
    },
    setMotif(state, obj) {
      state.motif.motifOptions[obj.index] = obj.data;
    },
    setCustomMotif(state, motif) {
      state.motif = motif;
    },
    updateMotifProperties(state, payload) {
      state.motif.defaultScaleX = payload.defaultScaleX;
      state.motif.defaultScaleY = payload.defaultScaleY;
    },
    setSelectedMotif(state, obj) {
      state.motif.motifOptions[obj.index].id = obj.id;
      state.motif.motifOptions[obj.index].name = obj.name;
      state.motif.motifOptions[obj.index].url = obj.url;
    },

    addPart(state) {
      let part = cloneDeep(state.part);
      state.parts.push(part);
    },
    editPart(state, index) {
      state.parts[index] = cloneDeep(state.part);
      state.part = state.defaultPart;
    },
    setOne(state, one) {
      state.one = one;
    },
    paperInProgress(state, yesOrNo) {
      state.paperInProgress = yesOrNo;
    },
    setTagsSuggestion(state, list) {
      list.filter((obj) => {
        if (obj.tags.length) {
          obj.tags.filter((tag) => {
            state.linersTags.push(tag);
          });
        }
      });
    },
    setImageTagsSuggestion(state, list) {
      list.filter((obj) => {
        if (obj.tags.length) {
          obj.tags.filter((tag) => {
            state.imageTags.push(tag);
          });
        }
      });
    },
    setColorTagsSuggestion(state, list) {
      list.filter((obj) => {
        if (obj.tags.length) {
          obj.tags.filter((tag) => {
            state.colorTags.push(tag);
          });
        }
      });
    },
    filterColorsByPrintName(state, payload) {
      let colorList = state.colorList;
      colorList.filter((color) => {
        color.colorHex = color.processHex[payload.printShortName]
          ? "#" + color.processHex[payload.printShortName]
          : "";
        color.colorName = color.name;
        if (color.colorHex) return color;
      });
      state.colorList = [];
      state.colorList = colorList;
    },
  },
  actions: {
    downSwappe({ commit }, index) {
      commit("setSelectedFontsDown", index);
    },
    upSwappe({ commit }, index) {
      commit("setSelectedFontsUp", index);
    },
    addDefaultFont({ commit }) {
      commit("setDefaultFonts");
    },
    removeFont({ commit }, index) {
      commit("removeFont", index);
    },
    setFont({ commit }, obj) {
      commit("setSelectedFont", obj);
    },
    setCustomFont({ commit }, font) {
      commit("setSelectedCustomFont", font);
    },
    fetchOne: async ({ commit }, productId) => {
      try {
        commit("inProgress", true);
        const list = await doFetchProductOne(productId);
        commit("setOne", humps(list[0]));
        commit("inProgress", false);
      } catch (err) {
        commit("inProgress", false);
        throw err;
      }
    },
    fetchOneBySku: async ({ commit }, productSku) => {
      try {
        commit("inProgress", true);
        const list = await doFetchProductOneBySku(productSku);
        commit("setOne", humps(list[0]));
        commit("inProgress", false);
      } catch (err) {
        commit("inProgress", false);
        throw err;
      }
    },
    fetchFonts: async ({ commit }) => {
      try {
        commit("fontsInProgress", true);
        const list = await doFetchFonts();
        commit("fontsInProgress", false);
        if (list && list.length > 0) {
          commit("setFontList", humps(list));
        } else commit("setFontList", []);
      } catch (err) {
        commit("inProgress", false);
        throw err;
      }
    },
    lookupFonts: async ({ commit }, search) => {
      try {
        commit("fontsInProgress", true);
        const list = await doLookupFonts(search);
        commit("fontsInProgress", false);
        if (list && list.length > 0) {
          commit("setFontList", humps(list));
        } else commit("setFontList", []);
      } catch (err) {
        commit("inProgress", false);
        throw err;
      }
    },
    fetchPaper: async ({ commit }) => {
      try {
        commit("inProgress", true);
        const list = await doFetchPaper();
        commit("inProgress", false);
        if (list && list.length > 0) {
          commit("setPaperList", humps(list));
        } else commit("setPaperList", []);
      } catch (err) {
        commit("inProgress", false);
        throw err;
      }
    },
    lookupPapers: async ({ commit }, search) => {
      try {
        commit("paperInProgress", true);
        const list = await doLookupPaper(search);
        commit("paperInProgress", false);
        if (list && list.length > 0) {
          commit("setPaperList", humps(list));
        } else commit("setPaperList", []);
      } catch (err) {
        commit("inProgress", false);
        throw err;
      }
    },
    fetchMotifs: async ({ commit }) => {
      try {
        commit("motifsInProgress", true);
        const list = await doFetchMotifs();
        commit("motifsInProgress", false);
        if (list && list.length > 0) {
          commit("setMotifList", humps(list));
        } else commit("setMotifList", []);
      } catch (err) {
        commit("inProgress", false);
        throw err;
      }
    },
    lookupMotifs: async ({ commit }, search) => {
      try {
        commit("setMotifList", []);
        commit("motifsInProgress", true);
        const list = await doLookupMotifs(search);
        commit("motifsInProgress", false);
        if (list && list.length > 0) {
          commit("setMotifList", humps(list));
        } else commit("setMotifList", []);
      } catch (err) {
        commit("inProgress", false);
        throw err;
      }
    },
    fetchMonograms: async ({ commit }) => {
      try {
        commit("monogramsInProgress", true);
        const list = await doFetchMonograms();
        commit("monogramsInProgress", false);
        if (list && list.length > 0) {
          commit("setMonogramList", humps(list));
        } else commit("setMonogramList", []);
      } catch (err) {
        commit("inProgress", false);
        throw err;
      }
    },
    lookupMonograms: async ({ commit }, search) => {
      try {
        commit("setMonogramList", []);
        commit("monogramsInProgress", true);
        const list = await doLookupMonograms(search);
        commit("monogramsInProgress", false);
        if (list && list.length > 0) {
          commit("setMonogramList", humps(list));
        } else commit("setMonogramList", []);
      } catch (err) {
        commit("inProgress", false);
        throw err;
      }
    },
    fetchLiners: async ({ commit }) => {
      try {
        commit("linersInProgress", true);
        const list = await doFetchLiners();
        commit("linersInProgress", false);
        if (list && list.length > 0) {
          commit("setLinersList", humps(list));
          commit("setTagsSuggestion", list);
        } else commit("setLinersList", []);
      } catch (err) {
        commit("linersInProgress", false);
        throw err;
      }
    },
    fetchColors: async ({ commit }) => {
      try {
        const list = await doFetchColors();
        if (list && list.length > 0) {
          commit("setColorList", humps(list));
          commit("setColorTagsSuggestion", list);
        } else commit("setColorList", []);
      } catch (err) {
        throw err;
      }
    },
    fetchImages: async ({ commit }) => {
      try {
        commit("imagesInProgress", true);
        const list = await doFetchImages();
        commit("imagesInProgress", false);
        if (list && list.length > 0) {
          commit("setImageTagsSuggestion", list);
        }
      } catch (err) {
        commit("imagesInProgress", false);
        throw err;
      }
    },
    paperSwapDown({ commit }, index) {
      commit("paperSwapDown", index);
    },
    paperSwapUp({ commit }, index) {
      commit("paperSwapUp", index);
    },
    addDefaultPaper({ commit }) {
      commit("addDefaultPaper");
    },
    removePaper({ commit }, index) {
      commit("removePaper", index);
    },
    setPaper({ commit }, obj) {
      commit("setPaper", obj);
    },
    setCustomPaper({ commit }, paper) {
      commit("setCustomPaper", paper);
    },

    motifSwapDown({ commit }, index) {
      commit("motifSwapDown", index);
    },
    motifSwapUp({ commit }, index) {
      commit("motifSwapUp", index);
    },
    addDefaultMotif({ commit }) {
      commit("addDefaultMotif");
    },
    removeMotif({ commit }, index) {
      commit("removeMotif", index);
    },
    setMotif({ commit }, obj) {
      commit("setSelectedMotif", obj);
    },
    setCustomMotif({ commit }, paper) {
      commit("setCustomMotif", paper);
    },
    updateMotifProperties({ commit }, { defaultScaleX, defaultScaleY }) {
      commit("updateMotifProperties", { defaultScaleX, defaultScaleY });
    },

    monogramSwapDown({ commit }, index) {
      commit("monogramSwapDown", index);
    },
    monogramSwapUp({ commit }, index) {
      commit("monogramSwapUp", index);
    },
    addDefaultMonogram({ commit }) {
      commit("addDefaultMonogram");
    },
    removeMonogram({ commit }, index) {
      commit("removeMonogram", index);
    },
    setMonogram({ commit }, obj) {
      commit("setSelectedMonogram", obj);
    },
    setCustomMonogram({ commit }, monogram) {
      commit("setCustomMonogram", monogram);
    },

    addPart({ commit }) {
      commit("addPart");
    },
    editPart({ commit }, obj) {
      commit("editPart", obj);
    },
    cloneProduct: async ({ commit }, { productObject, partList }) => {
      try {
        commit("inProgress", true);
        const product = await doPostCloneProduct(snakes(productObject));
        if (product) {
          for (let i = 0; i < partList.length; i++) {
            await doPostCloneProductParts(
              snakes(Object.assign({}, partList[i], { productId: product.id }))
            );
          }
        }
        return humps(product);
      } catch (err) {
        commit("inProgress", false);
        throw err;
      }
    },
    filterColorsByPrintName({ commit }, { printShortName }) {
      return commit("filterColorsByPrintName", { printShortName });
    },
    setSurfaceToPaper({ commit }, { currentPaper, surface }) {
      commit("setSurfaceToPaper", { currentPaper, surface });
    },
  },
};
