import {
  doFetchOne,
  doPostOne,
  doPatchOne,
} from "@/services/product_part.service";
import { getField, updateField } from "vuex-map-fields";
import humps from "lodash-humps";
import createHumps from "lodash-humps/lib/createHumps";
import { snakeCase } from "lodash";
const snakes = createHumps(snakeCase);
export const productPartOne = {
  namespaced: true,
  state: {
    one: {},
    inProgress: false,
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    setOne(state, one) {
      state.one = one;
      state.one.orderCount = state.one.orderCount
        ? state.one.orderCount.toString()
        : "0";
    },
    updateOneProperties(state, part) {
      // if (part.assets) {
      //   let newAssets = {
      //     ...state.one.assets,
      //     ...part.assets,
      //   };
      //   state.one = Object.assign({}, state.one, { assets: newAssets });
      // } else {
        state.one = Object.assign({}, state.one, part);
      // }
    },
    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo;
    },
  },
  actions: {
    new({ commit }) {
      commit("inProgress", false);

      commit("setOne", {
        name: "",
        appId: "crane",
        organizationId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        category: "",
        brand: "crane",
        assets: {},
        orderCount: "",
        svg: "",
        alternateSvg: "",
        thumbnailUrl: "",
        tags: [],
        status: null,
      });
    },
    fetchOne: async ({ commit }, { productPartId }) => {
      try {
        commit("inProgress", true);
        const list = await doFetchOne(productPartId);
        const one = humps(list[0]);
        commit("setOne", one);
        commit("inProgress", false);
        return one;
      } catch (err) {
        commit("inProgress", false);
        throw err;
      }
    },

    save: async ({ commit, state }, productId) => {
      commit("inProgress", true);
      try {
        if (undefined === state.one.id) {
          await doPostOne(
            snakes(Object.assign({}, state.one, { productId: productId }))
          );

          commit("inProgress", false);
          return { new: true };
        } else {
          await doPatchOne(
            snakes(Object.assign({}, state.one, { productId: productId }))
          );

          commit("inProgress", false);
          return { update: true };
        }
      } catch (err) {
        commit("inProgress", false);
        throw err;
      }
    },
    setOne: async ({ commit }, page) => {
      commit("setOne", humps(page));
    },
    update: async ({ commit }, products) => {
      commit("inProgress", true);
      try {
        await doPatchOne(snakes(products));
        commit("inProgress", false);
        return { update: true };
      } catch (err) {
        commit("inProgress", false);
        throw err;
      }
    },
    updateOneProperties: async ({ commit }, part) => {
      commit("updateOneProperties", part);
    },
  },
};
