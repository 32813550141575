import { wrapRequest } from "./nav";
const imageLibApiUrl = import.meta.env.VITE_IMAGELIB_API_URL;

export const doPostOne = wrapRequest(({ one }) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/vnd.pgrst.object+json",
      Prefer: "return=representation",
    },
    body: JSON.stringify({ ...one }),
  };
  return fetch(`${imageLibApiUrl}/incident`, requestOptions);
});
