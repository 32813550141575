<template>
  <div>
    <v-app-bar
      height="48"
      :absolute="!isScrolled"
      :app="isScrolled"
      :color="isScrolled ? 'text-white' : 'rgba(0,0,0,0.0)'"
      class="header-background"
      elevation="0"
    >
      <v-col class="d-flex justify-start pa-0">
        <v-app-bar-nav-icon
          @click.stop="menuAction()"
          :class="textColor"
          class="d-md-none"
        ></v-app-bar-nav-icon>

        <v-img
          :src="craneLogoBlue"
          class="cursor-pointer d-none d-md-flex ml-md-4"
          @click="goToHomePage()"
          max-width="100"
          max-height="48"
          aspect-ratio="1"
          contain
        ></v-img>
      </v-col>
      <v-col class="d-flex justify-center">
        <v-img
          :src="craneLogoBlue"
          class="cursor-pointer d-block d-md-none"
          @click="goToHomePage()"
          max-width="100"
          max-height="48"
          aspect-ratio="1"
          contain
        ></v-img>
        <v-toolbar-items align="center">
          <v-menu v-if="userAuth" v-model="showMenu">
            <template v-slot:activator="{ props }">
              <v-btn
                icon
                v-bind="props"
                :class="textColor"
                @click="showMenu = true"
                class="d-none d-md-block header-buttons mr-6"
                :max-width="$vuetify.display.mdOnly ? '35px' : ''"
              >
                <div class="text-uppercase">Hi {{ userName }}</div>
              </v-btn>
            </template>
            <v-card elevation="0" class="mt-3 custom-menu-dropdown">
              <v-list density="compact">
                <v-list-item>
                  <v-list-item-title
                    @click="goToLogout()"
                    class="cursor-pointer font-weight-medium text-caption"
                    >Logout</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
          <v-btn
            icon
            v-if="!userAuth"
            @click="goToLogin()"
            :class="textColor"
            class="d-none d-md-block header-buttons"
            :max-width="$vuetify.display.mdOnly ? '35px' : ''"
            variant="plain"
          >
            <v-icon class="header-action-icons"
              >mdi-account-circle-outline</v-icon
            >
          </v-btn>
        </v-toolbar-items>
      </v-col>
      <v-col class="d-flex justify-end">
        <p
          v-if="productOne"
          class="mb-0 body-font fontsize-16 text-center text-truncate"
        >
          {{ productOne.name }}
        </p>
      </v-col>
    </v-app-bar>

    <vue-snotify />
    <v-navigation-drawer
      v-model="showSearchInput"
      temporary
      color="#ffffff"
      :width="searchInputWidth"
      :location="showSearchInput ? 'right' : ''"
      :class="[
        showSearchInput
          ? 'headerpage-search-drawer-block'
          : 'headerpage-search-drawer-none',
        this.$route.name == 'HomePage' ? 'top-56' : 'top-0',
      ]"
    >
      <v-text-field
        v-model="search"
        autofocus
        clearable
        single-line
        placeholder="Search"
        solo
        type="text"
        class="header-search-field"
        @blur="showSearchInput = false"
        v-on:keyup.enter="GotoPage"
        variant="compact"
      >
        <template v-slot:append-inner>
          <v-icon @click="GotoPage()" color="primary">mdi-magnify</v-icon>
        </template>
      </v-text-field>
    </v-navigation-drawer>
  </div>
</template>
<script>
import { priceFormatter } from "@/services/util.service";
const storeFrontUrl = import.meta.env.VITE_APP_CRANE_DAILY_URL;

import craneLogoBlue from "@/assets/Crane_Logo_blue.png";
import craneLogoWhite from "@/assets/Crane_Logo_white.png";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Header",
  props: ["offsetTop", "heroData"],
  data: () => ({
    priceFormatter: priceFormatter,
    stockFlag: true,
    showMenu: false,
    chekoutFlag: true,
    drawer: false,
    subMenu: "",
    searchResultList: [],
    searchInProgress: false,
    lookupCatelog: null,
    showSearchInput: false,
    search: null,
    defaultWidth: 400,
    craneLogoBlue: craneLogoBlue,
    craneLogoWhite: craneLogoWhite,
  }),
  computed: {
    productOne() {
      return this.$store.state.designerOne.one;
    },
    snackbarFlag() {
      if (this.$store.state.userCartProduct.autoLogoutFlag) {
        this.showMessage({
          message: "Your session has expired please login again.",
          color: "error",
          show: true,
          timeout: 5000,
        });
      }
      return this.$store.state.userCartProduct.autoLogoutFlag;
    },
    userName: function () {
      return this.$store.state.accountOne.one.firstname;
    },
    userAuth: function () {
      return this.$store.state.auth.userAuth;
    },
    isDesktop: function () {
      return this.$vuetify.display.xs ? true : false;
    },
    productListWidth() {
      return window.innerHeight;
    },
    defaultHeight: function () {
      if (this.isDesktop) {
        return window.innerHeight;
      } else {
        return "200";
      }
    },
    newUser() {
      if (this.$vuetify.display.smAndDown) return false;
      let userVisits = this.$store.state.layout.user.userVisits;
      if (userVisits && Number(userVisits) > 1) return false;
      else return true;
    },

    isScrolled: function () {
      if (!this.heroData) return true;

      return this.offsetTop > 30 ? true : false;
    },
    textColor: function () {
      if (!this.heroData) return "content-color";

      if (this.offsetTop > 30) return "content-color";
      else return "text-white";
    },
    searchInputWidth() {
      return this.$vuetify.display.xs ? "100%" : "256";
    },
    cartDrawer: {
      get() {
        if (this.userAuth) {
          return this.$store.state.userCartProduct.cartDrawer;
        } else {
          return this.$store.state.cartProduct.cartDrawer;
        }
      },
      set() {},
    },
    cartProductsProgress: function () {
      if (this.userAuth) {
        return this.$store.state.userCartProduct.cartProductsProgress;
      } else {
        return this.$store.state.cartProduct.cartProductsProgress;
      }
    },
    inProgress: function () {
      if (this.userAuth) {
        return this.$store.state.userCartProduct.inProgress;
      } else {
        return this.$store.state.cartProduct.inProgress;
      }
    },
  },
  methods: {
    outside() {
      // this.cartDrawer=false;
      if (this.userAuth) {
        this.$store.dispatch("userCartProduct/cartDrawer", false);
      } else {
        this.$store.dispatch("cartProduct/cartDrawer", false);
      }
    },
    // inside: function(e) {
    //   console.log("clicked inside!", e);
    // },
    menuAction: function () {
      this.$emit("action", {
        showMenu: !this.showMenu,
        categoryList: this.categoryList,
      });
    },
    onActionScroll() {
      this.$emit("actionScroll");
    },
    subCategory(category) {
      return category.filter((obj) => obj.isActive && obj.childrenData != "");
    },
    resizeCart() {
      return this.sideBarHeight() && this.productListHeight();
    },
    productListHeight() {
      return window.innerHeight - 250;
    },
    sideBarHeight() {
      return window.innerHeight;
    },
    pageName() {
      return this.$route.name;
    },
    /* eslint-disable no-alert, no-console ,  no-debugger */
    goToDashboard() {
      window.open(`${storeFrontUrl}/me/account-dashboard`, "_self");
    },
    goToHomePage() {
      window.open(`${storeFrontUrl}/`, "_self");
    },
    goToLogin() {
      window.open(`${storeFrontUrl}/gate/sign-in`, "_self");
    },
    goToStoreLocator() {
      window.open(`${storeFrontUrl}/store-locator`, "_self");
    },
    goToLogout() {
      // localStorage.removeItem("cr_userQuoteId", null);
      //this.$store.dispatch("auth/logout");
      localStorage.removeItem("cr_checkOutTab");
      window.open(`${storeFrontUrl}/logout-success`, "_self");
      // window.open("/gate/sign-in", "_self");
    },
    showCartDrawer(flag) {
      if (this.userAuth) {
        this.$store.dispatch("userCartProduct/cartDrawer", flag);
      } else {
        this.$store.dispatch("cartProduct/cartDrawer", flag);
      }
    },
    closeCartDrawer(flag) {
      if (this.userAuth) {
        this.$store.dispatch("userCartProduct/cartDrawer", flag);
      } else {
        this.$store.dispatch("cartProduct/cartDrawer", flag);
      }
    },
    stopPropogation(event) {
      event.stopPropagation();
    },
    GotoPage() {
      // debugger
      // console.log(this.search)
      if (this.search) {
        // this.search = null;
        this.showSearchInput = false;
        this.searchResultList = [];
        let searchKeywords = this.search;
        // let skuNew = seletedData.title;
        window.open(
          `${storeFrontUrl}/search?pageNumber=1&pageSize=9&sortBy=relevance&q=${searchKeywords}&filter=e30=`,
          "_self"
        );
      }
    },
  },
  directives: {
    "click-outside": {
      bind: function (el, binding, vNode) {
        // Provided expression must evaluate to a function.
        if (typeof binding.value !== "function") {
          const compName = vNode.context.name;
          let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`;
          if (compName) {
            warn += `Found in component '${compName}'`;
          }

          console.warn(warn);
        }
        // Define Handler and cache it on the element
        const bubble = binding.modifiers.bubble;
        const handler = (e) => {
          if (bubble || (!el.contains(e.target) && el !== e.target)) {
            binding.value(e);
          }
        };
        el.__vueClickOutside__ = handler;
        // add Event Listeners
        document.addEventListener("click", handler);
      },
      unbind: function (el, binding) {
        console.warn(binding);
        // Remove Event Listeners
        document.removeEventListener("click", el.__vueClickOutside__);
        el.__vueClickOutside__ = null;
      },
    },
  },
};
</script>
<style src="./Header.scss" lang="scss" scopped />
