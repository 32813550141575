/*eslint-disable*/
import { authHeader } from "./auth";
import { wrapRequest } from "./nav";

const vueAppMagenotApiUrl = import.meta.env.VITE_APP_MAGENTO_API_URL;

export const doPostSessionWishlistItem = wrapRequest(
  ({ sku, customOptions }) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        ...authHeader(),
      },
      body: JSON.stringify({
        wishlistItem: {
          sku: sku,
          custom_options: customOptions,
        },
      }),
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/V1/wishlist/add`, requestOptions);
  }
);
