import { doFetchList } from "@/services/session_item.service";
import humps from "lodash-humps";

export const sessionItemList = {
  namespaced: true,
  state: {
    list: [],
    inProgress: false,
  },
  mutations: {
    setSessionItemList(state, list) {
      state.list = list;
    },
    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo;
    },
    updateItemProperty(state, params) {
      let currentList = state.list;
      for (let i = 0; i < currentList.length; i++) {
        let currentItem = currentList[i];
        if (currentItem.id === params.sessionItemId) {
          currentItem = {
            ...currentItem,
            ...params.property,
          };
          currentList[i] = currentItem;
        }
      }
      state.list = [];
      state.list = currentList;
    },
  },
  actions: {
    fetchList: async ({ commit }, { sessionId }) => {
      commit("inProgress", true);
      try {
        let response = await doFetchList({ sessionId });
        if (response && response.length) {
          commit("setSessionItemList", humps(response));
          return { response: humps(response) };
        } else if (response && response.length == 0) {
          return { response: null };
        }
        commit("inProgress", false);
        return { response: null };
      } catch (err) {
        commit("inProgress", false);
        throw err;
      }
    },
    updatePropertyInStore: async ({ commit }, { sessionItemId, property }) => {
      commit("inProgress", true);
      try {
        commit("updateItemProperty", { sessionItemId, property });
        return true;
      } catch (err) {
        commit("inProgress", false);
        throw err;
      }
    },
  },
};
