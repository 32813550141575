// /* eslint-disable no-debugger */
import {
  doFetchOne,
  doPostOne,
  doPatchOne,
  doPatchItemProperties,
  doDeleteSessionItem,
} from "@/services/session_item.service";
import humps from "lodash-humps";
import createHumps from "lodash-humps/lib/createHumps";
import { snakeCase } from "lodash";
const snakes = createHumps(snakeCase);

export const sessionItemOne = {
  namespaced: true,
  state: {
    one: {},
    inProgress: false,
  },
  mutations: {
    setSessionItem(state, one) {
      state.one = one;
    },
    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo;
    },
  },
  actions: {
    fetchOne: async (
      { commit },
      { itemId, sessionId, surface, surfaceType }
    ) => {
      commit("inProgress", true);
      try {
        let response = await doFetchOne({
          itemId,
          sessionId,
          surface,
          surfaceType,
        });
        if (response && response.length) {
          commit("setSessionItem", humps(response[0]));
          return { response: humps(response[0]) };
        } else if (response && response.length == 0) {
          return { response: null };
        }
        commit("inProgress", false);
        return { response: null };
      } catch (err) {
        commit("inProgress", false);
        throw err;
      }
    },
    createItem: async ({ commit }, { item }) => {
      commit("inProgress", true);
      try {
        let response = await doPostOne({ item: snakes(item) });
        commit("setSessionItem", humps(response));
        commit("inProgress", false);
        return { response: humps(response) };
      } catch (err) {
        commit("inProgress", false);
        throw err;
      }
    },
    updateItem: async ({ commit }, { sessionItemId, property }) => {
      commit("inProgress", true);
      try {
        let response = await doPatchOne({
          sessionItemId,
          property: snakes(property),
        });
        commit("setSessionItem", humps(response));
        commit("inProgress", false);
        return { response: humps(response) };
      } catch (err) {
        commit("inProgress", false);
        throw err;
      }
    },
    updateItemProperties: async (
      { commit },
      { sessionId, productPartId, surfaceType, surfaceId, property }
    ) => {
      commit("inProgress", true);
      try {
        let response = await doPatchItemProperties({
          sessionId,
          productPartId,
          surfaceType,
          surfaceId,
          property: snakes(property),
        });
        commit("setSessionItem", humps(response));
        commit("inProgress", false);
        return { response: humps(response) };
      } catch (err) {
        commit("inProgress", false);
        throw err;
      }
    },
    deleteSessionItem: async ({ commit }, { sessionId }) => {
      // debugger
      commit("inProgress", true);
      try {
        let deleteFlag = await doDeleteSessionItem(sessionId);
        commit("inProgress", false);
        return deleteFlag;
      } catch (err) {
        commit("inProgress", false);
        throw err;
      }
    },
  },
};
