import { doPostSessionWishlistItem } from "@/services/wishlist_item.service";
import { doFetchQuote } from "@/services/user_cart.service";
import { getField, updateField } from "vuex-map-fields";
import humps from "lodash-humps";

/* eslint-disable no-alert, no-console ,   */
export const wishlistItemOne = {
  namespaced: true,
  state: {
    inProgress: false,
    one: {},
    cartProductDetails: [],
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,

    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo;
    },
  },
  actions: {
    addSessionProductToWishlist: async (
      { commit, state },
      { sku, customOptions }
    ) => {
      try {
        commit("inProgress", true);
        let cartProduct = null;
        if (!state.cartProductDetails.length > 0) {
          const userQuote = await doFetchQuote();
          localStorage.setItem("cr_userQuoteId", userQuote); //no use
          cartProduct = await doPostSessionWishlistItem({ sku, customOptions }); // builderId,
        }

        commit("inProgress", false);

        return humps(cartProduct);
      } catch (err) {
        commit("inProgress", false);
        throw err;
      }
    },
  },
};
