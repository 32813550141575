import { doPostOne } from "@/services/incident.service";
import { getField, updateField } from "vuex-map-fields";
import createHumps from "lodash-humps/lib/createHumps";
import { snakeCase } from "lodash";
const snakes = createHumps(snakeCase);

export const incidentOne = {
  namespaced: true,
  state: {
    one: {},
    inProgress: false,
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    setOne(state, one) {
      state.one = one;
    },
    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo;
    },
  },
  actions: {
    setOne({ commit }) {
      commit("inProgress", false);
      commit("setOne", {
        title: "",
        app_id: "crane",
        level: null,
        description: "",
        module: "personalize",
        tags: [],
        recorded_at: "",
        organization_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        metadata: {},
      });
    },
    save: async ({ commit, state }, { one }) => {
      commit("inProgress", true);
      try {
        if (undefined === state.one.id) {
          let response = await doPostOne({
            one: snakes(Object.assign({}, state.one, one)),
          });
          commit("inProgress", false);
          return { response: response, new: true };
        }
      } catch (err) {
        commit("inProgress", false);
        throw err;
      }
    },
  },
};
