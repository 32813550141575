<template>
  <div v-scroll="onScroll" class="h-100" v-resize="OnResize">
    <UserNavigationDrawer :showMenu="showMenu" />
    <DesignerHeader
      v-bind:offsetTop="offsetTop"
      @action="onAction"
      v-if="isPersonalizepage"
    />
    <v-main class="h-100">
      <slot></slot>
      <vue-snotify />
    </v-main>
  </div>
</template>

<script>
import UserNavigationDrawer from "@/components/common/UserNavigationDrawer.vue";
import DesignerHeader from "@/components/layout/header/DesignerHeader.vue";
import { createHelpers } from "vuex-map-fields";
import { customAttributeValue } from "@/services/util.service";
const storeFrontUrl = import.meta.env.VITE_APP_CRANE_DAILY_URL;
const { mapFields } = createHelpers({
  getterType: "layout/getField",
  mutationType: "layout/updateField",
});

export default {
  name: "DesignerLayout",

  components: {
    DesignerHeader,
    UserNavigationDrawer,
  },

  data: () => ({
    offsetTop: 0,
    showMenu: false,
    categoryList: [],
    activeMainMenu: "",
    activeSubMenu: "",
    activeSubMenuItem: "",
    search: null,
    searchResultList: [],
    lookupCatelog: null,
    searchInProgress: false,
    pageNo: 1,
    pageSize: 9,
    sortSelect: { label: "Position", value: "position" },
  }),
  computed: {
    inProgress: function () {
      return this.$store.state.headerCategoryList.inProgress;
    },
    guestQuoteId: function () {
      return this.$store.state.cartProduct.guestQuoteId;
    },
    isDesktop: function () {
      return this.$vuetify.display.xs ? true : false;
    },
    isPersonalizepage: function () {
      let currentPage = this.$route.name;
      if (
        currentPage === "ProductPersonalizeDesignerMode" ||
        currentPage === "ProductPersonalize" ||
        currentPage === "ProductSummary" ||
        currentPage === "ProductSummaryDesignerMode"
      )
        return false;
      else return true;
    },
    designerModeFlag: function () {
      if (
        this.$route.params.designerMode &&
        this.$route.params.designerMode == "true"
      )
        return true;
      return false;
    },
    userAuth: function () {
      return this.$store.state.auth.userAuth;
    },
    userName: function () {
      return this.$store.state.accountOne.one.firstname;
    },
    ...mapFields(["user", "user.userVisits"]),
  },

  methods: {
    GotoPage() {
      this.showSearchInput = false;
      this.searchResultList = [];
      let searchKeywords = this.search;
      window.open(
        `${storeFrontUrl}/search?pageNumber=1&pageSize=9&sortBy=relevance&q=${searchKeywords}&filter=e30=`,
        "_self"
      );
    },
    onScroll() {
      this.offsetTop = window.pageYOffset || document.documentElement.scrollTop;
    },
    onAction(action) {
      this.showMenu = action.showMenu;
      this.categoryList = action.categoryList;
    },
    OnResize() {
      setTimeout(() => {
        if (this.$vuetify.display.mdAndUp) {
          this.showMenu = false;
        }
      }, 200);
    },
    goToLogout() {
      window.open(`${storeFrontUrl}/logout-success`, "_self");
    },
    goToDashboard() {
      window.open(`${storeFrontUrl}/me/account-dashboard`, "_self");
    },
    goToLogin() {
      window.open(`${storeFrontUrl}/gate/sign-in`, "_self");
    },
    goToStoreLocator() {
      window.open(`${storeFrontUrl}/store-locator`, "_self");
    },
    getCustomAttributeValue(customAttributes, attributeCode) {
      return customAttributeValue(customAttributes, attributeCode);
    },
  },
  created() {
    if (this.userAuth) {
      this.$store.dispatch("userCartProduct/cartProductList");
      this.$store.dispatch("userCartProduct/productTotalAmt");
      this.$store.dispatch("accountOne/basicInfo");
    } else {
      if (this.guestQuoteId) {
        this.$store.dispatch("cartProduct/cartProductList", this.guestQuoteId);
        this.$store.dispatch("cartProduct/productTotalAmt", this.guestQuoteId);
      }
    }
    if (this.userVisits) {
      let userVisits = Number(this.userVisits) + 1;
      localStorage.setItem("cr_craneUserVisitCount", userVisits);
      this.userVisits = userVisits;
    } else {
      localStorage.setItem("cr_craneUserVisitCount", 1);
      this.userVisits = 1;
    }
  },
};
</script>
<style lang="scss">
.header-input-custom {
  .v-input__control {
    background-color: transparent !important;
  }
  .v-input__slot {
    &:before {
      border-color: #ffffff !important;
      border-style: solid;
      border-width: 1px;
    }
    &:after {
      border-color: #ffffff !important;
      border-style: solid;
      border-width: 1px;
    }
  }
  .v-text-field__slot {
    input {
      color: #ffffff !important;
      border-bottom-color: #ffffff !important;
    }
    input::placeholder {
      color: #ffffff !important;
    }
    input:-ms-input-placeholder {
      color: #ffffff !important;
    }

    input ::-ms-input-placeholder {
      color: #ffffff !important;
    }
  }
  .v-icon {
    color: #ffffff !important;
  }
  .mobile-header-list.theme--light.v-list {
    background: #2805da;
  }
  .v-list-item__icon {
    color: #ffffff !important;
  }
  .z-index-1000 {
    z-index: 1000 !important;
  }
}
.mobile-header-autocomplete .v-input__append-inner .v-input__icon {
  display: none !important;
}
.mobile-header-autocomplete .theme--light.v-icon {
  color: #ffffff !important;
}
.mobile-header-autocomplete.theme--light.v-input input {
  color: #ffffff !important;
}
.mobile-header-autocomplete.theme--light.v-text-field--filled
  > .v-input__control
  > .v-input__slot {
  background-color: transparent !important;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
<style src="./Layout.scss" lang="scss" />
