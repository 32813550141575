import { createApp } from "vue";
import VueCookies from "vue3-cookies";
import App from "./App.vue";
import router from "./multipages";
import store from "./stores";
import vuetify from "@/plugins/vuetify";
import snotify from "vue3-snotify";
import VueCropper from "vue-cropper";

import "vuetify/lib/styles/settings/_variables.scss";

import "vuetify/dist/vuetify.min.css";
import "vue3-snotify/style";
import {
    fpjsPlugin
} from '@fingerprintjs/fingerprintjs-pro-vue-v3';
const fingerprintAPIKey = import.meta.env.VITE_FINGERPRINT_API_KEY;

import { FabricCanvas } from "@/components/FabricWrapper/components";
const app = createApp(App);
app.use(fpjsPlugin, {
    loadOptions: {
        apiKey: fingerprintAPIKey
    },
})
app.use(vuetify);
app.use(VueCookies);
app.use(snotify);
app.use(VueCropper);

app.use(store);
app.use(router);

app.component("FabricCanvas", FabricCanvas);
app.mount("#app");