import {
  doPostCartItem,
  doPostSessionCartItem,
  doFetchQuote,
  doFetchCartProducts,
  doFetchTotalAmt,
  doDeleteCartProduct,
  doPutCartItem,
  doPutSessionCartItem,
  doPutMiniCartItem,
  // doFetchCartId,
  doPutCConectionCartItem,
  doPutGiftCartItem,
  doPutConfiguredCartItem,
  doPostConfiguredCartItem,
  // doPostGiftCartItem
} from "@/services/user_cart.service";
import { doFetchList } from "@/services/session_item.service";

import { getCookieByName, removeCookieByName } from "@/services/auth";
import { getField, updateField } from "vuex-map-fields";
import humps from "lodash-humps";
import { getAuthUser } from "@/services/auth";
const crGuestQuoteId = import.meta.env.VITE_APP_GUEST_QUOTE_ID;
import {
  criteoViewBasket,
  listrakCartList,
  listrakCartListClear,
} from "@/services/util.service";
/* eslint-disable no-alert, no-console ,   */
export const userCartProduct = {
  namespaced: true,
  state: {
    cartDrawer: false,
    inProgress: false,
    fetchInProgress: false,
    cartProduct: [],
    cartProductList: [],
    productTotalAmt: [],
    productMedia: {},
    errorMessage: null,
    cartProductsProgress: false,
    cartTotalAmtProgress: false,
    cartProductDetails: [],
    giftCartDetails: {
      // "senderName": "",
      // "senderEmail": "",
      recipientName: "",
      recipientEmail: "",
      senderMessage: "",
      //"giftCardQty": "",
      giftCardAmt: "",
    },
    autoLogoutFlag: false,
    userQuoteId: null,
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,

    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo;
    },
    cartProductsProgress(state, yesOrNo) {
      state.cartProductsProgress = yesOrNo;
    },
    cartTotalAmtProgress(state, yesOrNo) {
      state.cartTotalAmtProgress = yesOrNo;
    },
    setCartDrawer(state, yesOrNo) {
      state.cartDrawer = yesOrNo;
    },
    fetchInProgress(state, yesOrNo) {
      state.fetchInProgress = yesOrNo;
    },
    setCartProduct(state, product) {
      state.cartProduct = product;
    },
    setCartProductList(state, list) {
      state.cartProductList = list;
    },
    setCartProductDetails(state, details) {
      state.cartProductDetails = details;
    },
    setProductAmt(state, amt) {
      state.productTotalAmt = amt;
    },
    setErrorMessage(state, err) {
      state.errorMessage = err;
    },
    setProductsMedia(state, media) {
      state.productMedia = media;
    },
    setAutoLogoutFlag(state, flag) {
      state.autoLogoutFlag = flag;
    },
    setUserQuoteId(state, quote) {
      state.userQuoteId = quote;
    },
    setListrakCartListClear() {
      listrakCartListClear();
    },
    setCriteoViewBasket(state) {
      criteoViewBasket(state.cartProductList);
    },
    setListrakCartList(state) {
      listrakCartList(state.cartProductList);
    },
  },
  actions: {
    addToCart: async ({ commit, state }, data) => {
      try {
        commit("inProgress", true);
        let cartProduct = null;
        if (!state.cartProductDetails.length > 0) {
          const userQuote = await doFetchQuote();
          localStorage.setItem("cr_userQuoteId", userQuote); //no use
          cartProduct = await doPostCartItem(
            data.sku,
            data.qty,
            data.ccid,
            data.price,
            data.personalizeDetails,
            data.builderId ? data.builderId : null,
            userQuote,
            data.optionId,
            data.optionValue
          );
        } else {
          cartProduct = await doPostCartItem(
            data.sku,
            data.qty,
            data.ccid,
            data.price,
            data.personalizeDetails,
            data.builderId ? data.builderId : null,
            state.cartProductDetails[0].quoteId,
            data.optionId,
            data.optionValue
          );
        }
        commit("inProgress", false);
        // commit('setCartDrawer', true);
        return cartProduct;
      } catch (err) {
        commit("inProgress", false);
        throw err;
      }
    },
    fetchUserQuote: async ({ commit }) => {
      commit("inProgress", true);
      const userQuote = await doFetchQuote();
      if (userQuote) {
        commit("setUserQuoteId", userQuote);
        commit("inProgress", false);
        return userQuote;
      }
      commit("inProgress", false);
      return null;
    },
    addSessionProductToCart: async (
      { commit, state },
      { sku, qty, price, productOption }
    ) => {
      try {
        commit("inProgress", true);
        let cartProduct = null;
        if (!state.cartProductDetails.length > 0) {
          const userQuote = await doFetchQuote();
          localStorage.setItem("cr_userQuoteId", userQuote); //no use
          cartProduct = await doPostSessionCartItem({
            sku,
            qty,
            price,
            userQuote,
            productOption,
          }); // builderId,
        } else {
          cartProduct = await doPostSessionCartItem({
            sku,
            qty,
            price,
            userQuote: state.cartProductDetails[0].quoteId,
            productOption,
          }); // builderId,
        }
        commit("inProgress", false);
        // commit('setCartDrawer', true);
        return cartProduct;
      } catch (err) {
        commit("inProgress", false);
        throw err;
      }
    },
    addToConfiguredCart: async ({ commit, state }, data) => {
      try {
        commit("inProgress", true);
        if (!state.cartProductDetails.length > 0) {
          const userQuote = await doFetchQuote();
          localStorage.setItem("cr_userQuoteId", userQuote); //no use
          await doPostConfiguredCartItem(
            data.sku,
            data.qty,
            data.typeId,
            data.optionId,
            data.optionValue,
            userQuote
          );
        } else {
          await doPostConfiguredCartItem(
            data.sku,
            data.qty,
            data.typeId,
            data.optionId,
            data.optionValue,
            state.cartProductDetails[0].quoteId
          );
        }
        commit("inProgress", false);
        //commit('setCartDrawer', true);
      } catch (err) {
        commit("inProgress", false);
        throw err;
      }
    },

    productTotalAmt: async ({ commit }) => {
      commit("cartTotalAmtProgress", true);
      doFetchTotalAmt()
        .then((productTotalAmt) => {
          commit("cartTotalAmtProgress", false);
          commit("setProductAmt", humps(productTotalAmt));
        })
        .catch(() => commit("cartTotalAmtProgress", false));
    },
    cartProductList: async (
      { commit, rootState, dispatch },
      builderSessionId
    ) => {
      commit("setCartProductList", []);
      commit("cartProductsProgress", true);
      try {
        const quoteId = await doFetchQuote();
        let guestQuoteId = await getCookieByName(crGuestQuoteId);
        if (guestQuoteId) {
          await dispatch("cartProduct/cartProductList", guestQuoteId, {
            root: true,
          });
          let guestCartProduct = rootState.cartProduct.cartProductList;
          if (guestCartProduct.length > 0) {
            for (let i = 0; i < guestCartProduct.length; i++) {
              if (guestCartProduct[i].productType == "configurable") {
                await dispatch("addToConfiguredCart", {
                  sku: guestCartProduct[i].parentSku,
                  qty: guestCartProduct[i].qty,
                  typeId: guestCartProduct[i].productType,
                  optionId:
                    guestCartProduct[i].productOption.attributesInfo[0]
                      .optionId,
                  optionValue:
                    guestCartProduct[i].productOption.attributesInfo[0]
                      .optionValue,
                  categoryLinkLocal: guestCartProduct[i].itemId,
                });
              } else if (guestCartProduct[i].productType == "giftcard") {
                await dispatch("addToGiftCart", {
                  sku: guestCartProduct[i].sku,
                  qty: guestCartProduct[i].qty,
                  senderName:
                    guestCartProduct[i].productOption.infoBuyRequest
                      .giftcardSenderName,
                  senderEmail:
                    guestCartProduct[i].productOption.infoBuyRequest
                      .giftcardSenderEmail,
                  categoryLinkLocal: guestCartProduct[i].itemId,
                  giftCartDetails: {
                    recipientName:
                      guestCartProduct[i].productOption.infoBuyRequest
                        .giftcardRecipientName,
                    recipientEmail:
                      guestCartProduct[i].productOption.infoBuyRequest
                        .giftcardRecipientEmail,
                    senderMessage:
                      guestCartProduct[i].productOption.infoBuyRequest
                        .giftcardMessage,
                    giftCardAmt:
                      guestCartProduct[i].productOption.infoBuyRequest
                        .giftcardAmount,
                  },
                });
              } else {
                let params = { optionId: null, optionValue: null };
                let newOptions =
                  guestCartProduct[i].options &&
                  guestCartProduct[i].options.options &&
                  guestCartProduct[i].options.options.length
                    ? guestCartProduct[i].options.options
                    : null;
                let builderSessionObj = null;
                if (newOptions) {
                  builderSessionObj = newOptions.find(
                    (x) => x && x.label && x.label.toLowerCase() == "buildersessionid"
                  );
                  if (builderSessionObj) {
                    params.optionId = builderSessionObj.optionId;
                    params.optionValue = builderSessionObj.optionValue;
                  }
                }
                console.log("builderSessionObj", builderSessionObj);

                if (
                  !(
                    builderSessionObj != null &&
                    builderSessionId == builderSessionObj.optionValue
                  )
                ) {
                  console.log("Ignore condition");
                  let output = await dispatch("addToCart", {
                    sku: guestCartProduct[i].sku,
                    qty: guestCartProduct[i].qty,
                    ccid: guestCartProduct[i].ccid
                      ? guestCartProduct[i].ccid
                      : null,
                    price: builderSessionObj
                      ? guestCartProduct[i].rowTotal
                      : null,
                    personalizeDetails: guestCartProduct[i].personalizeDetails
                      ? guestCartProduct[i].personalizeDetails
                      : null,
                    builderId: guestCartProduct[i].builderId
                      ? guestCartProduct[i].builderId
                      : null,
                    categoryLinkLocal: guestCartProduct[i].itemId,
                    ...params,
                  });

                  if (
                    output &&
                    rootState.accountOne.one &&
                    rootState.accountOne.one.id &&
                    params &&
                    params.optionValue
                  ) {
                    await dispatch(
                      "sessionOne/updateOne",
                      {
                        sessionId: params.optionValue,
                        property: {
                          quoteData: output,
                          userData: {
                            customer_id: rootState.accountOne.one.id,
                            customer_name: rootState.accountOne.one.firstname,
                          },
                        },
                      },
                      { root: true }
                    );
                  }
                }
              }
              //  localStorage.removeItem("quote_item_id"+guestCartProduct[i].itemId);
            }
          }

          removeCookieByName(crGuestQuoteId);
          const cartProductList = await doFetchCartProducts(quoteId);

          commit("setCartProductDetails", humps(cartProductList));
          commit("setCartProductList", humps(cartProductList));
          commit("cartProductsProgress", false);
          dispatch("productTotalAmt");
          return humps(cartProductList);
        } else {
          let cartProductList = await doFetchCartProducts(quoteId);

          //let newCartProductList=[];
          if (cartProductList.length > 0) {
            for (let i = 0; i < cartProductList.length; i++) {
              if (
                cartProductList[i] &&
                cartProductList[i].options &&
                cartProductList[i].options.options
              ) {
                let optionsList = cartProductList[i].options.options;
                let builderSessionOne = null;
                builderSessionOne = optionsList.find(
                  (x) => x && x.label && x.label.toLowerCase() == "buildersessionid"
                );

                if (builderSessionOne) {
                  let sessionId = builderSessionOne.option_value;
                  if (sessionId) {
                    const sessionData = await doFetchList({ sessionId });
                    if (sessionData && sessionData.length > 0) {
                      let thumbnailUrl = sessionData.map(
                        (a) => a.thumbnail_url
                      );
                      cartProductList[i]["thumbnail_url"] = thumbnailUrl;
                    }
                  }
                }
              }
            }
          }
          commit("setCartProductDetails", humps(cartProductList));
          commit("setCartProductList", humps(cartProductList));
          commit("cartProductsProgress", false);
          dispatch("productTotalAmt");
          return humps(cartProductList);
        }
        // return true;
      } catch (err) {
        commit("cartProductsProgress", false);
        throw err;
      }
    },
    removeCartProduct: async ({ commit, dispatch }, { productId }) => {
      try {
        commit("cartProductsProgress", true);
        let trueOrFalse = await doDeleteCartProduct(productId);
        if (trueOrFalse) {
          commit("cartProductsProgress", false);
          commit("cartTotalAmtProgress", false);
          commit("setCartDrawer", false);
          await dispatch("cartProductList");
          dispatch("setListrakCartListClear");
        }
      } catch (err) {
        commit("cartProductsProgress", false);
        throw err;
      }
    },
    setListrakCartListClear: async ({ commit, state }) => {
      if (state.cartProductList.length <= 0) {
        commit("setListrakCartListClear");
      }
    },
    setCriteoViewBasket: async ({ commit, state }) => {
      if (state.cartProductList.length > 0) {
        commit("setCriteoViewBasket");
      }
    },
    setListrakCartList: async ({ commit, state }) => {
      if (state.cartProductList.length > 0) {
        commit("setListrakCartList");
      }
    },
    updateToCart: async ({ commit, dispatch, state }, data) => {
      try {
        commit("inProgress", true);
        let userQuote = state.cartProductDetails[0].quoteId;
        const cartProduct = await doPutCartItem(
          data.sku,
          data.qty,
          data.price,
          userQuote,
          data.itemId,
          data.personalizeData
        );
        if (cartProduct) {
          commit("inProgress", false);
          //  commit('setCartDrawer', true);
          commit("setCartProduct", cartProduct);
          dispatch("cartProductList");
        }
      } catch (err) {
        dispatch("cartProductList");
        commit("inProgress", false);
        throw err;
      }
    },
    updateSessionProductToCart: async (
      { commit, dispatch },
      { sku, qty, price, userQuote, itemId, productOption }
    ) => {
      try {
        commit("inProgress", true);
        const cartProduct = await doPutSessionCartItem({
          sku,
          qty,
          price,
          userQuote,
          itemId,
          productOption,
        });
        if (cartProduct) {
          commit("inProgress", false);
          //  commit('setCartDrawer', true);
          commit("setCartProduct", cartProduct);
          dispatch("cartProductList");
        }
        return cartProduct;
      } catch (err) {
        dispatch("cartProductList");
        commit("inProgress", false);
        throw err;
      }
    },
    updateMiniCart: async ({ commit, dispatch, state }, data) => {
      try {
        let userQuote = state.cartProductDetails[0].quoteId;
        commit("cartProductsProgress", true);
        const cartProduct = await doPutMiniCartItem(
          data.sku,
          data.qty,
          userQuote,
          data.itemId,
          data.ccid,
          data.price,
          data.personalizeDetails
        );
        if (cartProduct) {
          commit("cartProductsProgress", false);
          // commit('setCartDrawer', true);
          commit("setCartProduct", cartProduct);
          await dispatch("cartProductList");
          return cartProduct;
        }
      } catch (err) {
        await dispatch("cartProductList");
        commit("cartProductsProgress", false);
        throw err;
      }
    },

    updateToCConectionCart: async ({ commit }, data) => {
      try {
        commit("cartProductsProgress", true);
        const response = await doPutCConectionCartItem(
          data.sku,
          data.qty,
          data.ccid
        );
        commit("cartProductsProgress", false);
        return response;
      } catch (err) {
        commit("cartProductsProgress", false);
        throw err;
      }
    },
    updateToConfiguredCart: async ({ commit, dispatch, state }, data) => {
      try {
        commit("cartProductsProgress", true);
        let userQuote = state.cartProductDetails[0].quoteId;
        const cartProduct = await doPutConfiguredCartItem(
          data.sku,
          data.qty,
          userQuote,
          data.itemId,
          data.optionId,
          data.optionValue
        );
        if (cartProduct) {
          //  commit('setCartDrawer', true);
          commit("setCartProduct", cartProduct);
          dispatch("cartProductList");
        }
      } catch (err) {
        dispatch("cartProductList");
        commit("cartProductsProgress", false);
        throw err;
      }
    },
    updateToGiftCart: async ({ commit, dispatch, state }, data) => {
      try {
        commit("inProgress", true);
        data.userQuote = state.cartProductDetails[0].quoteId;
        const cartProduct = await doPutGiftCartItem(
          data,
          state.giftCartDetails
        );
        if (cartProduct) {
          commit("inProgress", false);
          if (data.routeName != "CartProducts") {
            // commit('setCartDrawer', true);
          }
          commit("setCartProduct", cartProduct);
          dispatch("cartProductList");
        }
      } catch (err) {
        await dispatch("cartProductList");
        commit("inProgress", false);
        throw err;
      }
    },
    cartDrawer: async ({ commit }, flag) => {
      commit("setCartDrawer", flag);
    },
    checkUserToken: ({ commit, dispatch }, obj) => {
      let timeInterval = null;
      timeInterval = setInterval(() => {
        if (!getAuthUser()) {
          console.log("setInterval", timeInterval, obj);
          commit("setCartProductList", []);
          commit("setProductAmt", {});
          commit("setAutoLogoutFlag", true);
          dispatch("auth/logout", {}, { root: true });
          clearInterval(timeInterval);
        }
      }, 3000);
    },
  },
};
