import { wrapRequest } from "./nav";
const vueAppMagenotApiUrl = import.meta.env.VITE_APP_MAGENTO_API_URL;
const retailorId = import.meta.env.VITE_APP_RETAILOR_ID;

const vueAppNodeslsApiUrl = import.meta.env.VITE_NODESLS_API_URL;
export const doPostCartItem = wrapRequest(
  (sku, qty, price, personalizeData, quoteId, builderId) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        cartItem: {
          quote_id: quoteId,
          sku: sku,
          qty: qty,
        },
        order_total: price,
        personalize_Data: personalizeData,
        builder_id: builderId,
      }),
    };
    return fetch(
      `${vueAppMagenotApiUrl}/rest/V1/guest-carts/${quoteId}/items`,
      requestOptions
    );
  }
);

export const doPostSessionCartItem = wrapRequest(
  ({ sku, qty, price, guestQuoteId, productOption }) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        cartItem: {
          quote_id: guestQuoteId,
          sku: sku,
          qty: qty,
          product_option: productOption,
        },
        order_total: price,
      }),
    };
    return fetch(
      `${vueAppMagenotApiUrl}/rest/V1/guest-carts/${guestQuoteId}/items`,
      requestOptions
    );
  }
);

export const doFetchQuote = wrapRequest(() => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  };
  return fetch(`${vueAppMagenotApiUrl}/rest/V1/guest-carts`, requestOptions);
});
export const doFetchCartId = wrapRequest((quoteId) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  };
  return fetch(
    `${vueAppMagenotApiUrl}/rest/V1/guest-carts/${quoteId}`,
    requestOptions
  );
});
export const doFetchCartProducts = wrapRequest((guestCartId) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  };
  return fetch(
    `${vueAppMagenotApiUrl}/rest/V1/cart/data?cartid=${guestCartId}`,
    requestOptions
  );
});
export const doFetchTotalAmt = wrapRequest((quoteId) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  };

  return fetch(
    `${vueAppMagenotApiUrl}/rest/V1/guest-carts/${quoteId}/totals`,
    requestOptions
  );
});
export const doDeleteCartProduct = wrapRequest((productId, quoteId) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  };
  return fetch(
    `${vueAppMagenotApiUrl}/rest/V1/guest-carts/${quoteId}/items/${productId}`,
    requestOptions
  );
});
export const doPutMiniCartItem = wrapRequest(
  (sku, qty, quoteId, itemId, ccid, price, personalizeDetails) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        cartItem: {
          quote_id: quoteId,
          sku: sku,
          qty: qty,
          item_id: itemId,
        },
        ccid: ccid,
        order_total: price,
        personalize_Data: personalizeDetails,
      }),
    };
    return fetch(
      `${vueAppMagenotApiUrl}/rest/V1/guest-carts/${quoteId}/items/${sku}`,
      requestOptions
    );
  }
);
export const doPutCartItem = wrapRequest(
  (sku, qty, quoteId, price, itemId, personalizeData) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        cartItem: {
          quote_id: quoteId,
          sku: sku,
          qty: qty,
          item_id: itemId,
        },
        order_total: price,
        personalize_Data: personalizeData,
      }),
    };

    return fetch(
      `${vueAppMagenotApiUrl}/rest/V1/guest-carts/${quoteId}/items/${sku}`,
      requestOptions
    );
  }
);

export const doPutSessionCartItem = wrapRequest(
  ({ sku, qty, guestQuoteId, price, itemId, productOption }) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        cartItem: {
          quote_id: guestQuoteId,
          sku: sku,
          qty: qty,
          item_id: itemId,
          product_option: productOption,
        },
        order_total: price,
      }),
    };
    return fetch(
      `${vueAppMagenotApiUrl}/rest/V1/guest-carts/${guestQuoteId}/items/${sku}`,
      requestOptions
    );
  }
);

export const doFetchCoupon = wrapRequest((quoteId) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  };
  return fetch(
    `${vueAppMagenotApiUrl}/rest/V1/guest-carts/${quoteId}/coupons`,
    requestOptions
  );
});

export const doApplyDiscountCode = wrapRequest((code, quoteId) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  };
  return fetch(
    `${vueAppMagenotApiUrl}/rest/V1/guest-carts/${quoteId}/coupons/${code}`,
    requestOptions
  );
});
export const doRemoveDiscountCode = wrapRequest((quoteId) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  };
  return fetch(
    `${vueAppMagenotApiUrl}/rest/V1/guest-carts/${quoteId}/coupons`,
    requestOptions
  );
});
export const doPutCConectionCartItem = wrapRequest((sku, qty, ccid) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-type": "text/xml",
      "Access-Control-Allow-Origin": "*",
    },
  };
  return fetch(
    `${vueAppNodeslsApiUrl}/cc-update-cart-item-quantity?retailerID=${retailorId}&orderNumber=${ccid}&itemNumber=${sku}&quantity=${qty}`,
    requestOptions
  );
});
export const doPutConfiguredCartItem = wrapRequest(
  (sku, qty, quoteId, itemId, optionId, optionValue) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        cartItem: {
          quote_id: quoteId,
          sku: sku,
          qty: qty,
          item_id: itemId,
          product_option: {
            extension_attributes: {
              configurable_item_options: [
                {
                  option_id: optionId,
                  option_value: optionValue,
                },
              ],
            },
          },
        },
      }),
    };
    return fetch(
      `${vueAppMagenotApiUrl}/rest/V1/guest-carts/${quoteId}/items/${sku}`,
      requestOptions
    );
  }
);

export const doPutGiftCartItem = wrapRequest(
  (data, quoteId, giftCartDetailsGuest) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        cartItem: {
          sku: data.sku,
          qty: data.qty,
          quote_id: quoteId,
          item_id: data.itemId,
          product_option: {
            extension_attributes: {
              giftcard_item_option: {
                giftcard_sender_name: giftCartDetailsGuest.senderNameGuest,
                giftcard_sender_email: giftCartDetailsGuest.senderEmailGuest,
                giftcard_recipient_name:
                  giftCartDetailsGuest.recipientNameGuest,
                giftcard_recipient_email:
                  giftCartDetailsGuest.recipientEmailGuest,
                giftcard_message: giftCartDetailsGuest.senderMessageGuest,
                giftcard_amount: giftCartDetailsGuest.giftCardAmtGuest,
              },
            },
          },
        },
      }),
    };
    return fetch(
      `${vueAppMagenotApiUrl}/rest/V1/guest-carts/${quoteId}/items/${data.sku}`,
      requestOptions
    );
  }
);
