import {
  doFetchOne,
  doPostOne,
  doPatchOne,
  doFetchUploadSignatureAndPolicy,
  doS3Upload,
  doFetchUserImages,
  doFetchImages,
  doPostOneBuilderItem,
  doFetchBuilderItem,
  doClearCache,
  doDeleteSelected,
} from "@/services/image.service";
import { getField, updateField } from "vuex-map-fields";
import humps from "lodash-humps";
import createHumps from "lodash-humps/lib/createHumps";
import { snakeCase } from "lodash";
const snakes = createHumps(snakeCase);

export const imageOne = {
  namespaced: true,
  state: {
    one: {},
    inProgress: false,
    userImages: [],
    libraryImageList: [],
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    setOne(state, one) {
      state.one = one;
    },
    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo;
    },
    setUserImages(state, list) {
      state.userImages = list;
    },
    setLibraryImageList(state, list) {
      state.libraryImageList = list;
    },
  },
  actions: {
    new({ commit }) {
      commit("inProgress", false);

      commit("setOne", {
        name: "",
        appId: "crane",
        organizationId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        category: "",
        brand: "crane",
        url: "",
        ownerId: "",
        height: 0,
        width: 0,
        tags: [],
        status: null,
        tools_settings: {},
      });
    },
    fetchOne: async ({ commit }, { imageId }) => {
      try {
        commit("inProgress", true);
        const list = await doFetchOne(imageId);
        const one = humps(list[0]);
        commit("setOne", one);
        commit("inProgress", false);
      } catch (err) {
        commit("inProgress", false);
        throw err;
      }
    },
    fetchUserImages: async ({ commit }, { ownerId, sku }) => {
      try {
        commit("inProgress", true);
        const list = await doFetchUserImages(ownerId, sku);
        const one = humps(list);
        commit("setUserImages", one);
        commit("inProgress", false);
      } catch (err) {
        commit("inProgress", false);
        throw err;
      }
    },
    fetchImages: async ({ commit }, tags) => {
      commit("inProgress", true);
      const list = await doFetchImages(tags);
      commit("inProgress", false);
      if (list && list.length > 0) {
        commit("setLibraryImageList", humps(list));
        return list;
      } else commit("setLibraryImageList", []);
    },
    save: async ({ commit, state }, extraData) => {
      commit("inProgress", true);
      try {
        if (undefined === state.one.id) {
          let response = await doPostOne(
            snakes(Object.assign({}, state.one, extraData))
          );
          commit("inProgress", false);
          return { response: response, new: true };
        } else {
          let response = await doPatchOne(snakes(state.one));
          commit("inProgress", false);
          return { response: response, update: true };
        }
      } catch (err) {
        commit("inProgress", false);
        throw err;
      }
    },
    saveBuilderItem: async ({ commit }, extraData) => {
      commit("inProgress", true);
      try {
        let response = await doPostOneBuilderItem(
          snakes(Object.assign({}, extraData))
        );
        commit("inProgress", false);
        return { response: response, new: true };
      } catch (err) {
        commit("inProgress", false);
        throw err;
      }
    },
    fetchBuilderItems: async ({ commit }, extraData) => {
      try {
        commit("inProgress", true);
        const list = await doFetchBuilderItem(extraData);
        commit("inProgress", false);
        return humps(list);
      } catch (err) {
        commit("inProgress", false);
        throw err;
      }
    },
    clearCache: async ({ commit }) => {
      try {
        commit("inProgress", true);
        await doClearCache();
        commit("inProgress", false);
      } catch (err) {
        commit("inProgress", false);
        console.log(err);
      }
    },
    setOne: async ({ commit }, page) => {
      commit("setOne", humps(page));
    },
    update: async ({ commit }, image) => {
      commit("inProgress", true);
      try {
        await doPatchOne(snakes(image));
        commit("inProgress", false);
        return { update: true };
      } catch (err) {
        commit("inProgress", false);
        throw err;
      }
    },

    uploadToS3: async ({ commit }, payload) => {
      try {
        commit("inProgress", true);
        let signature = await doFetchUploadSignatureAndPolicy(
          payload.bucketName,
          payload.bucketRegion,
          payload.name,
          payload.type
        );
        if (signature) {
          let image = await doS3Upload(
            payload.data,
            signature.url,
            payload.type
          );
          if (image) {
            commit("inProgress", false);
          }
          return image;
        }
      } catch (err) {
        commit("inProgress", false);
        throw err;
      }
    },
    deleteSelectedImage: async ({ commit }, { imageId }) => {
      commit("inProgress", true);
      await doDeleteSelected(imageId);
      commit("inProgress", false);
    },
  },
};
