import { doFetchList } from "@/services/designer.service";
import { getField, updateField } from "vuex-map-fields";

export const designerList = {
  namespaced: true,
  state: {
    inProgress: true,
    list: [],
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,

    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo;
    },
    setList(state, list) {
      state.list = list;
    },
  },
  actions: {
    fetchList: async ({ commit }) => {
      commit("inProgress", true);
      try {
        const list = await doFetchList();
        if (list) {
          //  commit('setList', humps(list));
        } else commit("setList", []);
      } catch (error) {
        throw error;
      } finally {
        commit("inProgress", false);
      }
    },
  },
};
