export default [
  {
    path: "/builder",
    name: "Main",
    redirect: "/builder",
  },
  {
    path: "/test",
    name: "test",
    component: () =>
      import(/* webpackChunkName: "session" */ "@/routes/personalize/test.vue"),
    meta: {
      breadcrumb: {
        label: "test",
      },
      layout: "builder",
    },
  },
  {
    path: "/builder/session/start/:sku/:quantity/:designerMode?",
    name: "SessionStart",
    component: () =>
      import(
        /* webpackChunkName: "session" */ "@/routes/personalize/session_start.vue"
      ),
    meta: {
      breadcrumb: {
        label: "Session",
      },
      layout: "builder",
    },
  },
  {
    path: "/builder/session/resume/:sessionId",
    name: "SessionResume",
    component: () =>
      import(
        /* webpackChunkName: "session" */ "@/routes/personalize/session_resume.vue"
      ),
    meta: {
      breadcrumb: {
        label: "Session resume",
      },
      layout: "builder",
    },
  },
  {
    path: "/builder/select-part/:sessionId/:designerMode?",
    name: "PartSelection",
    component: () =>
      import(
        /* webpackChunkName: "builder" */ "@/routes/personalize/part_selection.vue"
      ),
    meta: {
      breadcrumb: {
        label: "PartSelection",
      },
      layout: "builder",
    },
  },

  {
    path: "/builder/personalize/:sessionId/:designerMode?",
    name: "ProductPersonalize",
    component: () =>
      import(
        /* webpackChunkName: "builder" */ "@/routes/personalize/product_personalize.vue"
      ),
    meta: {
      breadcrumb: {
        label: "ProductPersonalize",
      },
      layout: "builder",
    },
  },

  {
    path: "/builder/:sku/part-personalize/:partId/:quantity?",
    name: "Product",
    component: () =>
      import(
        /* webpackChunkName: "builder" */ "@/routes/personalize/product.vue"
      ),
    meta: {
      breadcrumb: {
        label: "Product",
      },
      layout: "builder",
    },
  },
  {
    path: "/builder/:sku/part-personalize/:partId/:designerMode/:quantity?",
    name: "ProductDesignerMode",
    component: () =>
      import(
        /* webpackChunkName: "builder" */ "@/routes/personalize/product.vue"
      ),
    meta: {
      breadcrumb: {
        label: "Product",
      },
      layout: "builder",
    },
  },
  {
    path: "/builder/summary/:sessionId/:designerMode?",
    name: "ProductSummary",
    component: () =>
      import(
        /* webpackChunkName: "builder" */ "@/routes/personalize/product_summary.vue"
      ),
    meta: {
      breadcrumb: {
        label: "Product Summary",
      },
      layout: "builder",
    },
  },

  {
    path: "/designer/:sku",
    name: "designer",
    component: () =>
      import(
        /* webpackChunkName: "designer" */ "@/routes/personalize/designer.vue"
      ),
    meta: {
      breadcrumb: {
        label: "Designer",
      },
      layout: "designer",
    },
  },

  {
    path: "/editor/:sku",
    name: "editor",
    component: () =>
      import(
        /* webpackChunkName: "designer" */ "@/routes/personalize/editor.vue"
      ),
    meta: {
      breadcrumb: {
        label: "Editor",
      },
      layout: "designer",
    },
  },
];
