import { registrationService } from "@/services/registration.service";
import { getField, updateField } from "vuex-map-fields";

export const registration = {
  namespaced: true,
  state: {
    one: {},
    forgotOne: {},
    resetOne: {},
    inProgress: false,
    webDomain: null,
    errorMessage: null,
    resetPasswordMessage: null,
    signUpMessage: null,
    newPasswordMessage: null,
    newPasswordError: null,
    resetPasswordError: null,
    confirmEmailMessage: null,
    confirmEmailErrorMessage: null,
    forgotPasswordMessage: null,
    forgotPasswordError: null,
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    setOne(state, one) {
      state.one = one;
    },
    setForgotOne(state, forgotOne) {
      state.forgotOne = forgotOne;
    },
    setResetOne(state, resetOne) {
      state.resetOne = resetOne;
    },
    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo;
    },
    errorMessage(state, errorMessage) {
      state.errorMessage = errorMessage;
    },
    resetPasswordMessage(state, resetPasswordMessage) {
      state.resetPasswordMessage = resetPasswordMessage;
    },
    signUpMessage(state, signUpMessage) {
      state.signUpMessage = signUpMessage;
    },
    newPasswordMessage(state, newPasswordMessage) {
      state.newPasswordMessage = newPasswordMessage;
    },
    resetPasswordError(state, resetPasswordError) {
      state.resetPasswordError = resetPasswordError;
    },
    newPasswordError(state, newPasswordError) {
      state.newPasswordError = newPasswordError;
    },
    confirmEmailInProgress(state, yesOrNo) {
      state.confirmEmailInProgress = yesOrNo;
    },
    confirmEmailMessage(state, message) {
      state.confirmEmailMessage = message;
    },
    confirmEmailErrorMessage(state, message) {
      state.confirmEmailErrorMessage = message;
    },
    forgotPasswordMessage(state, forgotPasswordMessage) {
      state.forgotPasswordMessage = forgotPasswordMessage;
    },
    forgotPasswordError(state, forgotPasswordError) {
      state.forgotPasswordError = forgotPasswordError;
    },
    resetState(state) {
      (state.inProgress = false),
        (state.one = {
          email: "",
          firstname: "",
          lastname: "",
          password: "",
          newsletter: true,
        }),
        (state.errorMessage = null),
        (state.resetPasswordMessage = null),
        (state.signUpMessage = null),
        (state.newPasswordMessage = null),
        (state.newPasswordError = null),
        (state.resetPasswordError = null),
        (state.confirmEmailMessage = null),
        (state.confirmEmailErrorMessage = null),
        (state.forgotPasswordMessage = null),
        (state.forgotPasswordError = null);
    },
  },
  actions: {
    newUserRagistration({ commit }) {
      commit("setOne", {
        email: "",
        firstname: "",
        lastname: "",
        password: "",
        newsletter: true,
      });
    },
    newForgotPassword({ commit }) {
      commit("inProgress", true);
      try {
        commit("setForgotOne", {
          email: "",
        });
        commit("inProgress", false);
      } catch (err) {
        commit("inProgress", false);
      }
    },
    newResetPassword({ commit }, { userIdAndToken }) {
      commit("setResetOne", {
        newPassword: "",
        token: userIdAndToken.token,
        email: userIdAndToken.email,
      });
    },
    resetPassword: async ({ commit, state }) => {
      commit("inProgress", true);
      try {
        let data = await registrationService.doResetPassword(state.resetOne); //userId, newPassword, token
        commit("inProgress", false);
        commit("resetPasswordMessage", data.massages);
        return { resetPassword: true };
      } catch (error) {
        commit("inProgress", false);
        commit("resetPasswordError", error);
        setTimeout(() => {
          commit("resetPasswordError", null);
        }, 3000);
      }
    },
    forgotPassword: async ({ commit, state }) => {
      commit("inProgress", true);
      try {
        let data = await registrationService.doForgotPassword(state.forgotOne);
        commit("inProgress", false);
        commit("forgotPasswordMessage", data.massages);
        return { forgotPassword: true };
      } catch (error) {
        commit("inProgress", false);
        commit(
          "forgotPasswordError",
          "Please enter email registered with your account."
        );
        setTimeout(() => {
          commit("forgotPasswordError", null);
        }, 3000);
      }
    },
    confirmEmail: async ({ commit }, token) => {
      commit("inProgress", true);
      try {
        let data = await registrationService.doConfirmEmail(token);
        commit("inProgress", false);
        commit("confirmEmailMessage", data.massages);
        // return { confirmEmail: true }
      } catch (error) {
        commit("inProgress", false);
        commit("confirmEmailErrorMessage", error);
        setTimeout(() => {
          commit("confirmEmailErrorMessage", null);
        }, 3000);
      }
    },
    signUp: async ({ commit, dispatch, state }) => {
      try {
        // eslint-disable-next-line

        commit("inProgress", true);
        let result = await registrationService.doSignUp(state.one);
        await dispatch(
          "auth/setOne",
          {
            email: state.one.email,
            password: state.one.password,
            newsletter: state.one.newsletter,
            result: result,
          },
          { root: true }
        );
        await dispatch("auth/login", {}, { root: true });
        commit("inProgress", false);
        return { registration: true };
      } catch (err) {
        commit("inProgress", false);
        if (err && err.message) {
          commit("errorMessage", err.message);
        } else {
          commit("errorMessage", err);
        }

        setTimeout(() => {
          commit("errorMessage", null);
        }, 3000);
        return { registration: false, errorMessage: err.message };
      }
    },
    newPassword: async ({ commit }, { password }) => {
      try {
        commit("inProgress", true);
        const user = await registrationService.doNewPassword(password);
        if (user) {
          commit("resetPasswordMessage", user);
        }
      } catch (error) {
        commit("inProgress", false);
        commit("resetPasswordError", error);
        setTimeout(() => {
          commit("resetPasswordError", null);
        }, 3000);
      }
    },
    resetState({ commit }) {
      commit("resetState");
    },
  },
};
