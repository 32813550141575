import { getField, updateField } from "vuex-map-fields";
import humps from "lodash-humps";
import { doFetchHeroContent } from "@/services/hero_content.service";
import config from "@/config/index";
const { product } = config;
export const layout = {
  namespaced: true,
  state: {
    product,
    user: {
      userVisits: localStorage.getItem("cr_craneUserVisitCount")
        ? localStorage.getItem("cr_craneUserVisitCount")
        : 0,
    },
    heroContent: {},
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo;
    },
    setHeroContent(state, content) {
      state.heroContent = content;
    },
  },
  actions: {
    fetchHeroContent: async ({ commit }, categoryId) => {
      try {
        commit("inProgress", true);
        const heroContent = await doFetchHeroContent(categoryId);
        if (heroContent) {
          commit("inProgress", false);
          commit("setHeroContent", humps(heroContent));
        }
        return humps(heroContent);
      } catch (error) {
        commit("inProgress", false);
        throw error;
      }
    },
  },
};
