<template>
  <v-app color="white">
    <!-- Layout component -->
    <component :is="currentLayout">
      <!-- <transition name="fade" mode="out-in"> -->
      <router-view />
      <!-- </transition> -->
    </component>
  </v-app>
</template>

<script>
import defaultLayout from "@/components/layout/DefaultLayout.vue";
import builderLayout from "@/components/layout/BuilderLayout.vue";
import designerLayout from "@/components/layout/DesignerLayout.vue";

const branch = import.meta.env.VITE_GIT_BRANCH_NAME;
const commitHash = import.meta.env.VITE_GIT_COMMIT_HASH;
const commitDate = import.meta.env.VITE_GIT_COMMIT_DATE;
// const commitAuthor = import.meta.env.VITE_GIT_COMMIT_AUTHOR;

export default {
  components: {
    defaultLayout,
    designerLayout,
    builderLayout,
  },
  name: "App",
  created() {
    console.info("Build - ", commitHash, branch, commitDate); //commitAuthor,
  },
  computed: {
    currentLayout: function () {
      const layout = this.$route.meta.layout || "default";

      return layout + "Layout";
    },
  },
};
</script>
<style src="@/styles/_app.scss" lang="scss" />
