export default [
  {
    path: "/gate",
    name: "LoginPage",
    redirect: "gate/sign-in",
    component: () =>
      import(/* webpackChunkName: "gate" */ "@/components/gate/GateLayout.vue"),
    children: [
      {
        path: "sign-in",
        name: "SignInPage",
        component: () =>
          import(/* webpackChunkName: "gate" */ "@/routes/gate/signin.vue"),
        meta: {
          breadcrumb: {
            label: "sign in",
          },
        },
      },
    ],
  },
];
