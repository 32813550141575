/* eslint-disable */
import {
  doFetchColors,
  doFetchFontByIds,
  doFetchFonts,
  doFetchLiners,
  doFetchMonogramByIds,
  doFetchPaperByIds,
  doFetchProductCategory,
  doFetchProductDetails,
  doFetchProductsAttributes,
  doFetchProductThumbnail,
  doFetchSessionProductDetails,
  doLookupPricerDataset,
} from "@/services/product.service";
import { uploadContentToS3 } from "@/services/user.service";
import humps from "lodash-humps";
import { getField, updateField } from "vuex-map-fields";

export const productOne = {
  namespaced: true,
  state: {
    inProgress: true,
    drawer: false,
    sheet: false,
    fontAPIList: [],
    partStates: [],
    productDetails: {},
    linerSolidList: [],
    linerPatternList: [],
    fetchPriceInProgress: false,
    colorList: [],
    productCategoryDetails: "",
    //svg saving changes
    isDirty: false,
    isSaveInProgress: false,
    sessionProductDetails: null,
    productStyle: {},
    productPricerDataset: null,
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,

    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo;
    },
    setNavigator(state, value) {
      state.drawer = value;
    },
    setSummarySheet(state, value) {
      state.sheet = value;
    },
    setFontAPIList(state, list) {
      state.fontAPIList = list;
    },
    setLinersList(state, list) {
      state.linerSolidList = [];
      state.linerPatternList = [];
      list.filter((obj) => {
        if (obj.style == "solid") state.linerSolidList.push(obj);
        else state.linerPatternList.push(obj);
      });
    },
    setColorList(state, list) {
      state.colorList = list;
    },
    filterColorsByPrintName(state, payload) {
      let colorList = state.colorList;
      colorList.filter((color) => {
        color.colorHex = color.processHex[payload.printShortName]
          ? "#" + color.processHex[payload.printShortName]
          : "";
        color.colorName = color.name;
        if (color.colorHex) return color;
      });
      state.colorList = [];
      state.colorList = colorList;
    },
    setProductsDetails(state, details) {
      state.productDetails = details;
    },
    setProductsAttributes(state, list) {
      state.productAttributes = list;
    },
    setProductCategory(state, category) {
      state.productCategoryDetails = category;
    },
    setIsDirty(state, isDirtyFlag) {
      state.isDirty = isDirtyFlag;
    },
    setIsSaveInProgress(state, isSaveInProgress) {
      state.isSaveInProgress = isSaveInProgress;
    },
    setSessionProductsDetails(state, productDetail) {
      state.sessionProductDetails = productDetail;
    },
    setProductStyles(state, data) {
      if (state.productStyle[data.styleTypeFor]) {
        state.productStyle[data.styleTypeFor][data.name] = data.style;
      } else {
        state.productStyle[data.styleTypeFor] = {};
        state.productStyle[data.styleTypeFor][data.name] = data.style;
      }
    },
    setPricerDataset(state, pricerDataset) {
      state.productPricerDataset = pricerDataset;
    },
  },
  actions: {
    fetchProductDetails: async ({ commit }, skuId) => {
      try {
        commit("inProgress", true);
        const productDetails = await doFetchProductDetails(skuId);
        if (productDetails) {
          commit("setProductsDetails", humps(productDetails[0]));
          commit("inProgress", false);
          return humps(productDetails[0]);
        }
      } catch (e) {
        commit("inProgress", false);
        return { error: true };
      }
    },
    fetchProductCategory: async ({ commit }, categoryId) => {
      try {
        commit("inProgress", true);
        const categoryDetails = await doFetchProductCategory(categoryId);
        if (categoryDetails) {
          commit("setProductCategory", humps(categoryDetails));
          commit("inProgress", false);
          return humps(categoryDetails);
        }
      } catch (e) {
        commit("inProgress", false);
        return { error: true };
      }
    },
    fetchProductAttribute: async ({ commit }, skuId) => {
      commit("inProgress", true);
      const productAttributes = await doFetchProductsAttributes(skuId);
      if (productAttributes) {
        commit("setProductsAttributes", humps(productAttributes));
        commit("inProgress", false);
        return { productAttributes };
      }
    },
    setNavigation({ commit }, value) {
      commit("setNavigator", value);
    },
    summarySheet({ commit }, value) {
      commit("setSummarySheet", value);
    },
    fetchFonts: async ({ commit }) => {
      commit("inProgress", true);
      const list = await doFetchFonts();
      if (list && list.length > 0) {
        commit("setFontAPIList", humps(list));
        commit("inProgress", false);
        return list;
      } else commit("setFontAPIList", []);
      commit("inProgress", false);
    },
    fetchLiners: async ({ commit }, tags) => {
      commit("inProgress", true);
      const list = await doFetchLiners(tags);
      if (list && list.length > 0) {
        commit("setLinersList", humps(list));
        commit("inProgress", false);
        return list;
      } else commit("setLinersList", []);
      commit("inProgress", false);
    },
    fetchColors: async ({ commit }, tags) => {
      commit("inProgress", true);
      const list = await doFetchColors(tags);
      if (list && list.length > 0) {
        commit("setColorList", humps(list));
        commit("inProgress", false);
        return list;
      } else commit("setColorList", []);
      commit("inProgress", false);
    },
    filterColorsByPrintName({ commit }, { printShortName }) {
      return commit("filterColorsByPrintName", { printShortName });
    },
    fetchProductThumbnail: async ({ commit }, { cartId, itemId }) => {
      // try{
      commit("inProgress", true);
      let thumbnailRes = await doFetchProductThumbnail(cartId, itemId);
      commit("inProgress", false);
      return thumbnailRes;
      // }
      // catch(error){
      //commit('inProgress', false);
      //     throw error;
      // }
    },
    fetchPaperByIds: async ({ commit }, paperIds) => {
      commit("inProgress", true);
      const paper = await doFetchPaperByIds(paperIds);
      commit("inProgress", false);
      if (paper && paper.length > 0) {
        return humps(paper);
      }
    },
    fetchMonogramByIds: async ({ commit }, monogramIds) => {
      commit("inProgress", true);
      const monograms = await doFetchMonogramByIds(monogramIds);
      commit("inProgress", false);
      if (monograms && monograms.length > 0) {
        return humps(monograms);
      }
    },
    fetchFontsByIds: async ({ commit }, fontIds) => {
      commit("inProgress", true);
      const fonts = await doFetchFontByIds(fontIds);
      commit("inProgress", false);
      if (fonts && fonts.length > 0) {
        return humps(fonts);
      }
    },
    userMadeChangesInSvg: async ({ commit }, { isDirty }) => {
      commit("setIsDirty", isDirty);
    },
    uploadContentToS3: async (
      { commit },
      { bucketName, bucketRegion, name, type, data }
    ) => {
      commit("setIsSaveInProgress", true);
      await uploadContentToS3({ bucketName, bucketRegion, name, type, data });
      commit("setIsDirty", false);
      commit("setIsSaveInProgress", false);
    },
    fetchSessionProductDetails: async ({ commit }, { sku }) => {
      try {
        commit("inProgress", true);
        const productDetails = await doFetchSessionProductDetails({ sku });
        if (productDetails) {
          commit("setSessionProductsDetails", humps(productDetails));
          commit("inProgress", false);
          return humps(productDetails);
        }
        commit("inProgress", false);
      } catch (e) {
        return { error: true };
      }
    },
    setProductStyles: async ({ commit }, { name, style, styleTypeFor }) => {
      try {
        commit("setProductStyles", { name, style, styleTypeFor });
      } catch (e) {
        console.log(e);
        return { error: true };
      }
    },
    lookupPricerDataset: async ({ commit }, { papers, uses, occassions }) => {
      try {
        commit("inProgress", true);
        const pricerDataset = await doLookupPricerDataset({
          papers,
          uses,
          occassions,
        });
        if (pricerDataset) {
          let resp = humps(pricerDataset);
          commit("setPricerDataset", resp);
          commit("inProgress", false);
          return resp;
        }
        commit("inProgress", false);
      } catch (e) {
        console.log(e);
        return { error: true };
      }
    },
  },
};
