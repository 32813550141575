import {
  doFetchOne,
  doPatchOne,
  doDeleteSession,
} from "@/services/session.service";
import humps from "lodash-humps";
import createHumps from "lodash-humps/lib/createHumps";
import { snakeCase } from "lodash";
const snakes = createHumps(snakeCase);

export const sessionOne = {
  namespaced: true,
  state: {
    one: {},
    inProgress: false,
  },
  mutations: {
    setSession(state, one) {
      state.one = one;
    },
    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo;
    },
  },
  actions: {
    fetchOne: async ({ commit }, { sessionId }) => {
      commit("inProgress", true);
      try {
        let response = await doFetchOne({ sessionId });
        commit("setSession", humps(response));
        commit("inProgress", false);
        return { response: humps(response) };
      } catch (err) {
        commit("inProgress", false);
        throw err;
      }
    },
    updateOne: async ({ commit }, { sessionId, property }) => {
      commit("inProgress", true);
      try {
        let response = await doPatchOne({
          sessionId,
          property: snakes(property),
        });
        commit("setSession", humps(response));
        commit("inProgress", false);
        return { response: humps(response) };
      } catch (err) {
        commit("inProgress", false);
        throw err;
      }
    },
    deleteSession: async ({ commit }, { sessionId }) => {
      // eslint-disable-next-line no-debugger
      // debugger
      commit("inProgress", true);
      try {
        let deleteFlag = await doDeleteSession(sessionId);
        commit("inProgress", false);
        return deleteFlag;
      } catch (err) {
        commit("inProgress", false);
        throw err;
      }
    },
  },
};
