//import { authHeader } from './auth';
import { wrapRequest } from "./nav";
const vueAppMagenotApiUrl = import.meta.env.VITE_APP_MAGENTO_API_URL;

export const doFetchProductsList = wrapRequest(() => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer 58rcjzlop08412gs0xa42q3qna21zxo2",
      "Access-Control-Allow-Origin": "*",
    },
  };
  return fetch(
    `${vueAppMagenotApiUrl}/rest/all/V1/crproducts?searchCriteria[current_page]=1&searchCriteria[page_size]=27`,
    requestOptions
  );
});
export const doFetchProductsMedia = wrapRequest((sku) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer 58rcjzlop08412gs0xa42q3qna21zxo2",
      "Access-Control-Allow-Origin": "*",
    },
  };
  return fetch(
    `${vueAppMagenotApiUrl}/rest/all/V1/crproducts/${sku}?fields=sku,media_gallery_entries[file]`,
    requestOptions
  );
});
export const doFetchProductsAttributes = wrapRequest((sku) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer 58rcjzlop08412gs0xa42q3qna21zxo2",
      "Access-Control-Allow-Origin": "*",
    },
  };
  return fetch(
    `${vueAppMagenotApiUrl}/rest/all/V1/crproducts/${sku}?fields=sku,custom_attributes`,
    requestOptions
  );
});
export const doFetchAttributeList = wrapRequest(() => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-type": "application/json",
    },
  };
  return fetch(
    `${vueAppMagenotApiUrl}/rest/all/V1/crproducts/attributes?searchCriteria=&fields=items[attribute_id,attribute_code,options]`,
    requestOptions
  );
});

export const doFetchProductDetails = wrapRequest((sku) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  };
  return fetch(`${vueAppMagenotApiUrl}/rest/V2/product/${sku}`, requestOptions);
});

export const doFetchCategoryList = wrapRequest(() => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-type": "application/json",
    },
  };
  return fetch(
    `${vueAppMagenotApiUrl}/rest/all/V1/crcategories`,
    requestOptions
  );
});
export const doFetchCatelogProducts = wrapRequest((searchCriteria) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-type": "application/json" },
  };

  /*
     return fetch(`https://m2-stg.crane.com/search/ajax/suggest/?q=${searchCriteria.split(" ").join("+")}`, requestOptions)*/

  return fetch(
    `${vueAppMagenotApiUrl}/rest/all/V1/crproducts?searchCriteria[filter_groups][0][filters][0][field]=name&searchCriteria[filter_groups][0][filters][0][value]=%25${searchCriteria}%25&searchCriteria[filter_groups][0][filters][0][condition_type]=like&searchCriteria[filter_groups][1][filters][0][field]=status&searchCriteria[filter_groups][1][filters][0][value]=1&searchCriteria[filter_groups][1][filters][0][condition_type]=eq&searchCriteria[pageSize]=20&fields=items[sku,name]`,
    requestOptions
  );
});

export const doFetchRecentlyViewedList = wrapRequest((sku) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-type": "application/json",
    },
  };
  return fetch(
    `${vueAppMagenotApiUrl}/rest/all/V1/crproducts?searchCriteria[filter_groups][0][filters][0][field]=sku&searchCriteria[filter_groups][0][filters][0][value]=${sku}&searchCriteria[filter_groups][0][filters][0][condition_type]=in&searchCriteria[sortOrders][0][direction]=ASC`,
    requestOptions
  );
});
