import { doAdminLogin } from "@/services/auth.service";
import {
  getAdminAuthUser,
  setAdminAuthUser,
  resetAdminAuthUser,
} from "@/services/auth";
import { getField, updateField } from "vuex-map-fields";

export const adminAuth = {
  namespaced: true,
  state: {
    one: {},
    inProgress: false,
    userAuth: getAdminAuthUser(),
    signInMessage: null,
    errorMessage: null,
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    setOne(state, one) {
      state.one = one;
    },
    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo;
    },
    loginSuccess(state, token) {
      state.inProgress = false;
      state.errorMessage = null;
      state.userAuth = token;
      setAdminAuthUser(token);
    },
    loginFailure(state, errorMessage) {
      state.inProgress = false;
      state.userAuth = null;
      state.errorMessage = errorMessage;
    },
    prepareWithMessage(state, message) {
      state.inProgress = false;
      state.userAuth = null;
      state.signInMessage = message;
    },
    logout(state) {
      state.inProgress = false;
      state.userAuth = null;
      resetAdminAuthUser();
    },
    resetState(state) {
      state.inProgress = false;
      state.userAuth = getAdminAuthUser();
      state.errorMessage = null;
      state.signInMessage = null;
    },
  },
  actions: {
    newUserLogin({ commit }) {
      commit("setOne", {
        email: "",
        password: "",
      });
    },
    login: async ({ commit, state }) => {
      try {
        commit("inProgress", true);
        const token = await doAdminLogin(state.one);
        if (token) {
          commit("loginSuccess", token);
          const url = sessionStorage.studio_interrupted_page;
          if (url) {
            window.location.href = url;
            sessionStorage.removeItem("studio_interrupted_page");
            sessionStorage.removeItem("studio_interrupted_message");
          } else window.location.href = "/designer/193C";
        }
      } catch (err) {
        commit("inProgress", false);
        commit("prepareWithMessage");
        commit("loginFailure", err.message);
        setTimeout(() => {
          commit("loginFailure", null);
        }, 3000);
      }
    },
    logout({ commit }) {
      commit("logout");
    },
    prepare({ commit }, message) {
      commit("loginFailure");
      commit("prepareWithMessage", message);
    },
    resetState({ commit }) {
      commit("resetState");
    },
  },
};
