import {
  doFetchList,
  dolookUpList,
  doDeleteSelected,
  doPatchList,
} from "@/services/product_part.service";
import { getField, updateField } from "vuex-map-fields";
import humps from "lodash-humps";

export const productPartList = {
  namespaced: true,
  state: {
    list: [],
    selected: [],
    userSelectedParts: [],
    allProductParts: [],
    inProgress: false,
    error: null,
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo;
    },
    setList(state, list) {
      state.selected = [];
      state.list = list;
    },
    setSelectedParts(state, parts) {
      state.userSelectedParts = parts;
    },
    setAllProductParts(state, parts) {
      state.allProductParts = parts;
    },
    setImageFaliedFlag(state, list) {
      state.list = [];
      state.list = list;
    },
    removePartFromList(state, partId) {
      if (state.userSelectedParts.indexOf(partId) != -1) {
        state.userSelectedParts.splice(
          state.userSelectedParts.indexOf(partId),
          1
        );
      }
    },
    addPartToList(state, partId) {
      if (state.userSelectedParts.indexOf(partId) == -1) {
        state.userSelectedParts.push(partId);
      }
    },
  },
  actions: {
    fetchList: async ({ commit }, { productId, sku }) => {
      commit("inProgress", true);
      try {
        const list = await doFetchList(productId);
        if (list) {
          if (sku) {
            let partArray = localStorage.getItem(
              "cr_" + sku + "_selectedPartList"
            );
            if (partArray) {
              commit("setSelectedParts", partArray.split(","));
            } else {
              commit(
                "setSelectedParts",
                list.map((obj) => obj.id)
              );
            }
            commit(
              "setAllProductParts",
              list.map((obj) => obj.id)
            );
          }
          commit("setList", humps(list));
          return humps(list);
        } else commit("setList", []);
      } catch (error) {
        throw error;
      } finally {
        commit("inProgress", false);
      }
    },
    fetchItemPartList: async (
      { commit },
      { sku, productId, cartId, itemId }
    ) => {
      commit("inProgress", true);
      try {
        const partList = await doFetchList(productId);
        let personalizeDetails = [];

        const mergedParts = partList.map((t1) => ({
          ...t1,
          ...personalizeDetails.find((t2) => t2.id === t1.id),
        }));

        if (partList) {
          let partArray = localStorage.getItem(
            `cr_${cartId}_${itemId}_${sku}_selectedPartList`
          );
          if (partArray) {
            commit("setSelectedParts", partArray.split(","));
          } else {
            commit(
              "setSelectedParts",
              mergedParts.map((obj) => {
                if (obj.partSelected == true) return obj.id;
              })
            );
          }

          commit("setList", humps(mergedParts));
          return humps(mergedParts);
        } else commit("setList", []);
      } catch (error) {
        throw error;
      } finally {
        commit("inProgress", false);
      }
    },
    patchList: async ({ commit }, list) => {
      commit("inProgress", true);
      try {
        doPatchList(list);
      } catch (error) {
        throw error;
      } finally {
        commit("inProgress", false);
      }
    },
    searchProduct: async ({ commit }, { product }) => {
      commit("inProgress", true);
      const list = await dolookUpList(product);
      commit("inProgress", false);
      if (list && list.length > 0) {
        commit("setList", humps(list));
      } else commit("setList", []);
    },
    deleteOne: async ({ commit }, { productPartId }) => {
      commit("inProgress", true);
      const deleteFlag = await doDeleteSelected(productPartId);
      commit("inProgress", false);
      return deleteFlag;
    },
    setImageFaliedFlag: async ({ commit }, list) => {
      commit("setImageFaliedFlag", list);
    },
    setList: async ({ commit }, list) => {
      commit("setList", list);
    },
    removePartFromList: async ({ commit }, { productId }) => {
      commit("removePartFromList", productId);
    },
    addPartToList: async ({ commit }, { productId }) => {
      commit("addPartToList", productId);
    },
  },
};
