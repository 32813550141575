import { createRouter, createWebHistory } from "vue-router";
import { beforeEachRoute } from "@/services/nav";

// Routes
import PersonalizeRoutes from "./personalize.routes";
import GateRoutes from "./gate.routes";

export const routes = [
  ...GateRoutes,
  ...PersonalizeRoutes,
  {
    path: "/:pathMatch(.*)*",
    name: "error",
    component: () =>
      import(
        /* webpackChunkName: "gate" */ "@/routes/personalize/page_not_found.vue"
      ),
    meta: {},
  },
];

const router = createRouter({
  history: createWebHistory(),
  base: import.meta.env.BASE_URL || "/",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
  routes,
});

router.beforeEach(beforeEachRoute);

export default router;
