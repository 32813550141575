const theme = {
  defaultTheme: "light",
  themes: {
    light: {
      dark: false,
      colors: {
        primary: "#2c50da",
        secondary: "#8A8D93",
        success: "#56CA00",
        info: "#16B1FF",
        warning: "#FFB400",
        error: "#FF4C51",
        background: "#F4F5FA",
      },
    },
    dark: {
      dark: false,
      colors: {
        primary: "#2196f3",
        secondary: "#8A8D93",

        success: "#56CA00",
        info: "#16B1FF",
        warning: "#FFB400",
        error: "#FF4C51",
      },
    },
  },
};
export default theme;
