import {
  doFetchBasicInfo,
  doFetchChangePassword
} from "@/services/user.service";
import { getField, updateField } from "vuex-map-fields";
import humps from "lodash-humps";

export const accountOne = {
  namespaced: true,
  state: {
    one: {},
    changedPasswordFlag: true,
    errorMessage: null,
    //successMessage: null,
    inProgress: false,
    paymentInProgress: false,
    fetchInProgress: false,
    wrongPasswordMessage: "",
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    setOne(state, one) {
      state.one = Object.assign({}, state.one, one);
    },
    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo;
    },
    fetchInProgress(state, yesOrNo) {
      state.fetchInProgress = yesOrNo;
    },
    wrongPasswordMessage(state, message) {
      state.wrongPasswordMessage = message;
    },
    errorMessage(state, error) {
      state.errorMessage = error;
    },
  },
  actions: {
    basicInfo: async ({ commit }) => {
      try {
        commit("inProgress", true);
        commit("fetchInProgress", true);
        const basicInfo = await doFetchBasicInfo();
        if (basicInfo) {
          commit("setOne", humps(basicInfo));
        }
        commit("inProgress", false);
        commit("fetchInProgress", false);
      } catch (err) {
        commit("inProgress", false);
        commit("fetchInProgress", false);

        commit("errorMessage", err.message);
      }
    },
    changePassword: async ({ commit }, { currentPassword, newPassword }) => {
      try {
        const changedPassword = await doFetchChangePassword(
          currentPassword,
          newPassword
        );
        commit("setChangePasswordFlag", changedPassword);
      } catch (err) {
        commit("errorMessage", err.message);
      }
    }
  },
};
