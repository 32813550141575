import { doLogin } from "@/services/gate.service";
import { redirectToInterruptedPage } from "@/services/nav";
import { getAuthUser, setAuthUser, resetAuthUser } from "@/services/auth";
import { getField, updateField } from "vuex-map-fields";
import { doUpdateSubscription } from "@/services/registration.service";

export const auth = {
  namespaced: true,
  state: {
    one: {},
    inProgress: false,
    userAuth: getAuthUser(),
    signInMessage: null,
    errorMessage: null,
    isRedirect: true,
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    setOne(state, one) {
      state.one = one;
    },
    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo;
    },
    loginSuccess(state, token) {
      state.inProgress = false;
      state.errorMessage = null;
      state.userAuth = token;
      setAuthUser(token);
    },
    loginFailure(state, errorMessage) {
      state.inProgress = false;
      state.userAuth = null;
      state.errorMessage = errorMessage;
    },
    prepareWithMessage(state, message) {
      state.inProgress = false;
      state.userAuth = null;
      state.signInMessage = message;
    },
    logout(state) {
      state.inProgress = false;
      state.userAuth = null;
      resetAuthUser();
    },
    resetState(state) {
      state.inProgress = false;
      state.userAuth = getAuthUser();
      state.errorMessage = null;
      state.signInMessage = null;
    },
    changeRedirectFlag(state, flag) {
      state.isRedirect = flag;
    },
  },
  actions: {
    newUserLogin({ commit }) {
      commit("setOne", {
        email: "",
        password: "",
      });
    },
    login: async ({ commit, state, dispatch }) => {
      try {
        commit("inProgress", true);
        const token = await doLogin(state.one);
        if (token) {
          commit("loginSuccess", { token: token, logged: state.one.logged });
          dispatch("userCartProduct/checkUserToken", {}, { root: true });
         // dispatch("getUserImages", state.one.email);
          commit("loginSuccess", token);
          if (state.one.newsletter) {
            await doUpdateSubscription(state.one.result);
          }
          if (state.isRedirect) redirectToInterruptedPage();

          return true;
        }
      } catch (err) {
        commit("inProgress", false);
        commit("loginFailure", err.message);
      }
    },
    logout({ commit }) {
      commit("logout");
    },
    prepare({ commit }, message) {
      commit("prepareWithMessage", message);
    },
    resetState({ commit }) {
      commit("resetState");
    },
    changeRedirectFlag({ commit }, flag) {
      commit("changeRedirectFlag", flag);
    },
    setOne({ commit }, one) {
      commit("setOne", one);
    },
  },
};
