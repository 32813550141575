import { createStore } from "vuex";

import { adminAuth } from "./gate/admin.auth.module";

import { auth } from "./personalize/auth.module";
import { registration } from "./personalize/registration.module";
import { layout } from "./personalize/layout";
import { productOne } from "./personalize/product.one";
import { accountOne } from "./personalize/account.one";
import { productList } from "./personalize/product.list";
import { designerOne } from "./designer/designer.one";
import { designerList } from "./designer/designer.list";
import { productPartList } from "./designer/product_part.list";
import { productPartOne } from "./designer/product_part.one";
import { orderOne } from "./personalize/order.one";
import { userCartProduct } from "./personalize/user_cart.one";
import { cartProduct } from "./personalize/guest_cart.one";
import { imageOne } from "./personalize/image.one";
import { sessionStartOne } from "./personalize/session_start.one";
import { wishlistItemOne } from "./personalize/wishlist_item.one";
import { sessionOne } from "./personalize/session.one";
import { sessionList } from "./personalize/session.list";
import { sessionItemOne } from "./personalize/session_item.one";
import { sessionItemList } from "./personalize/session_item.list";
import { incidentOne } from "./personalize/incident.one";
import { pricerBundleOne } from "./personalize/pricer_bundle.one";

import { headerCategoryList } from "./personalize/header";

const store = createStore({
  strict: import.meta.env.NODE_ENV !== "production",
  modules: {
    adminAuth,

    auth,
    registration,
    layout,
    productOne,
    productList,
    designerOne,
    designerList,
    productPartList,
    productPartOne,
    orderOne,
    userCartProduct,
    cartProduct,
    headerCategoryList,
    accountOne,
    imageOne,
    sessionStartOne,
    wishlistItemOne,
    sessionOne,
    sessionList,
    sessionItemOne,
    sessionItemList,
    incidentOne,
    pricerBundleOne,
  },
});
export default store;
