import { doPostOne } from "@/services/session.service";
import { getField, updateField } from "vuex-map-fields";
import humps from "lodash-humps";
import createHumps from "lodash-humps/lib/createHumps";
import { snakeCase } from "lodash";
const snakes = createHumps(snakeCase);

export const sessionStartOne = {
  namespaced: true,
  state: {
    one: {},
    inProgress: false,
    currentSession: null,
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    setCurrentSession(state, one) {
      state.currentSession = one;
    },
    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo;
    },
  },
  actions: {
    doStartNewSession: async ({ commit }, { specs }) => {
      commit("inProgress", true);
      try {
        let response = await doPostOne({ specs: snakes(specs) });
        commit("setCurrentSession", humps(response));
        commit("inProgress", false);
        return { response: humps(response), new: true };
      } catch (err) {
        commit("inProgress", false);
        throw err;
      }
    },
  },
};
