import { doFetchList } from "@/services/session.service";
import humps from "lodash-humps";

export const sessionList = {
  namespaced: true,
  state: {
    list: [],
    inProgress: false,
  },
  mutations: {
    setList(state, list) {
      state.list = list;
    },
    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo;
    },
  },
  actions: {
    fetchSessionList: async ({ commit }, { sku, guestId, userId }) => {
      commit("inProgress", true);
      try {
        let response = await doFetchList({ sku, guestId, userId });
        commit("setList", humps(response));
        commit("inProgress", false);
        return { response: humps(response) };
      } catch (err) {
        commit("inProgress", false);
        throw err;
      }
    },
  },
};
