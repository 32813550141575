import { createVuetify } from "vuetify";
import "vuetify/styles";

import theme from "./theme";

export default createVuetify({
  ssr: true,

  theme,
});
