import { doFetchPartPrice } from "@/services/order.service";
import { getField, updateField } from "vuex-map-fields";

export const orderOne = {
  namespaced: true,
  state: {
    inProgress: false,
    price: {},
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,

    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo;
    },
    setPrice(state, price) {
      state.price = price;
    },
  },
  actions: {
    fetchPartPrice: async ({ commit }, orderObject) => {
      try {
        commit("inProgress", true);
        let price = await doFetchPartPrice({ order: orderObject });
        commit("inProgress", false);
        return price;
      } catch (err) {
        commit("inProgress", false);
        throw err;
      }
    },
  },
};
