import {
  doPostCartItem,
  doPostSessionCartItem,
  doFetchQuote,
  doFetchCartProducts,
  doFetchTotalAmt,
  doDeleteCartProduct,
  doPutCartItem,
  doPutSessionCartItem,
  doPutMiniCartItem,
  doFetchCartId,
  doPutCConectionCartItem,
  doPutConfiguredCartItem,
  doPutGiftCartItem,
} from "@/services/guest_cart.service";
import { doFetchList } from "@/services/session_item.service";
import { getField, updateField } from "vuex-map-fields";
import humps from "lodash-humps";
import { getCookieByName, setCookieByName } from "@/services/auth";
const crGuestQuoteId = import.meta.env.VITE_APP_GUEST_QUOTE_ID;
export const cartProduct = {
  namespaced: true,
  state: {
    cartDrawer: false,
    inProgress: false,
    fetchInProgress: false,
    cartProduct: [],
    cartProductList: [],
    productTotalAmt: [],
    productMedia: {},
    errorMessage: null,
    cartProductsProgress: false,
    cartTotalAmtProgress: false,
    guestQuoteId: getCookieByName(crGuestQuoteId),
    giftCartDetailsGuest: {
      senderNameGuest: "",
      senderEmailGuest: "",
      recipientNameGuest: "",
      recipientEmailGuest: "",
      senderMessageGuest: "",
      //"giftCardQty": "",
      giftCardAmtGuest: "",
    },
    giftMessageData: {},
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,

    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo;
    },
    cartProductsProgress(state, yesOrNo) {
      state.cartProductsProgress = yesOrNo;
    },
    cartTotalAmtProgress(state, yesOrNo) {
      state.cartTotalAmtProgress = yesOrNo;
    },
    setCartDrawer(state, yesOrNo) {
      state.cartDrawer = yesOrNo;
    },
    fetchInProgress(state, yesOrNo) {
      state.fetchInProgress = yesOrNo;
    },
    setCartProduct(state, product) {
      state.cartProduct = product;
    },
    setCartProductList(state, list) {
      state.cartProductList = list;
    },
    setProductAmt(state, amt) {
      state.productTotalAmt = amt;
    },
    setProductsMedia(state, media) {
      state.productMedia = media;
    },
    setErrorMessage(state, err) {
      state.errorMessage = err;
    },
    setGuestQuoteId(state, quote) {
      state.guestQuoteId = quote;
    },
  },
  actions: {
    addToCart: async ({ commit }, data) => {
      try {
        commit("inProgress", true);
        let cartProduct = null;
        let guestQuoteId = getCookieByName(crGuestQuoteId);
        if (!guestQuoteId) {
          const guestQuote = await doFetchQuote();
          setCookieByName(crGuestQuoteId, guestQuote);
          cartProduct = await doPostCartItem(
            data.sku,
            data.qty,
            data.price,
            data.personalizedData,
            guestQuote,
            data.builderId
          );
          if (cartProduct) {
            commit("setCartProduct", cartProduct);
            const cartProductList = await doFetchCartProducts(guestQuote);
            if (cartProductList) {
              commit("setCartProductList", humps(cartProductList));
              const productTotalAmt = await doFetchTotalAmt(guestQuote);
              if (productTotalAmt) {
                commit("setProductAmt", humps(productTotalAmt));
              }
            }
          }
        } else {
          cartProduct = await doPostCartItem(
            data.sku,
            data.qty,
            data.price,
            data.personalizedData,
            guestQuoteId,
            data.builderId
          );
          if (cartProduct) {
            commit("setCartProduct", cartProduct);
            const cartProductList = await doFetchCartProducts(guestQuoteId);
            if (cartProductList) {
              commit("setCartProductList", humps(cartProductList));
              const productTotalAmt = await doFetchTotalAmt(guestQuoteId);
              if (productTotalAmt) {
                commit("setProductAmt", humps(productTotalAmt));
              }
            }
          }
        }
        commit("inProgress", false);
        // commit('setCartDrawer', true);
        return cartProduct;
      } catch (err) {
        commit("inProgress", false);
        throw err;
      }
    },
    fetchGuestQuote: async ({ commit }) => {
      commit("inProgress", true);
      let guestQuoteId = getCookieByName(crGuestQuoteId);
      if (!guestQuoteId) {
          guestQuoteId = await doFetchQuote();
          setCookieByName(crGuestQuoteId, guestQuoteId);
          commit("setGuestQuoteId", guestQuoteId);
          commit("inProgress", false);
          return guestQuoteId;
      }
      commit("inProgress", false);
      return guestQuoteId;
    },

    addSessionProductToCart: async (
      { commit },
      { sku, qty, price, productOption }
    ) => {
      try {
        commit("inProgress", true);
        let cartProduct = null;
        let guestQuoteId = getCookieByName(crGuestQuoteId);
        if (!guestQuoteId) {
          const guestQuote = await doFetchQuote();
          setCookieByName(crGuestQuoteId, guestQuote);
          cartProduct = await doPostSessionCartItem({
            sku,
            qty,
            price,
            productOption,
            guestQuoteId: guestQuote,
          }); // builderId
          if (cartProduct) {
            commit("setCartProduct", cartProduct);
            const cartProductList = await doFetchCartProducts(guestQuote);
            if (cartProductList) {
              commit("setCartProductList", humps(cartProductList));
              const productTotalAmt = await doFetchTotalAmt(guestQuote);
              if (productTotalAmt) {
                commit("setProductAmt", humps(productTotalAmt));
              }
            }
          }
        } else {
          cartProduct = await doPostSessionCartItem({
            sku,
            qty,
            price,
            productOption,
            guestQuoteId,
          }); // builderId
          if (cartProduct) {
            commit("setCartProduct", cartProduct);
            const cartProductList = await doFetchCartProducts(guestQuoteId);
            if (cartProductList) {
              commit("setCartProductList", humps(cartProductList));
              const productTotalAmt = await doFetchTotalAmt(guestQuoteId);
              if (productTotalAmt) {
                commit("setProductAmt", humps(productTotalAmt));
              }
            }
          }
        }
        commit("inProgress", false);
        // commit('setCartDrawer', true);
        return cartProduct;
      } catch (err) {
        commit("inProgress", false);
        throw err;
      }
    },
    productTotalAmt: async ({ commit }, guestQuoteId) => {
      commit("cartTotalAmtProgress", true);
      doFetchTotalAmt(guestQuoteId)
        .then((productTotalAmt) => {
          commit("cartTotalAmtProgress", false);
          commit("setProductAmt", humps(productTotalAmt));
        })
        .catch(() => commit("cartTotalAmtProgress", false));
    },
    cartProductList: async ({ commit }, guestQuoteId) => {
      try {
        commit("setCartProductList", []);
        commit("cartProductsProgress", true);
        let cartProductDetails = await doFetchCartId(guestQuoteId);
        if (cartProductDetails) {
          let cartProductList = await doFetchCartProducts(
            cartProductDetails.id
          );
          //let newCartProductList=[];
          if (cartProductList.length > 0) {
            for (let i = 0; i < cartProductList.length; i++) {
              if (
                cartProductList[i] &&
                cartProductList[i].options &&
                cartProductList[i].options.options
              ) {
                let optionsList = cartProductList[i].options.options;
                let builderSessionOne = null;
                builderSessionOne = optionsList.find(
                  (x) => x && x.label && x.label.toLowerCase() == "buildersessionid"
                );

                if (builderSessionOne) {
                  let sessionId = builderSessionOne.option_value;
                  if (sessionId) {
                    const sessionData = await doFetchList({ sessionId });
                    if (sessionData && sessionData.length > 0) {
                      // let newobj=cartProductList[i];
                      let thumbnailUrl = sessionData.map(
                        (a) => a.thumbnail_url
                      );
                      cartProductList[i]["thumbnail_url"] = thumbnailUrl;
                      //newCartProductList.push(newobj);
                    }
                  }
                }
              }
            }
          }
          commit("setCartProductList", humps(cartProductList));
          commit("cartProductsProgress", false);
          return humps(cartProductList);
        }
      } catch (err) {
        commit("cartProductsProgress", false);
      }
    },
    removeCartProduct: async ({ commit, dispatch }, productId) => {
      let guestQuoteId = getCookieByName(crGuestQuoteId);
      try {
        commit("cartProductsProgress", true);
        let trueOrFalse = await doDeleteCartProduct(productId, guestQuoteId);
        if (trueOrFalse) {
          commit("cartProductsProgress", false);
          // commit('setCartDrawer', false);
          await dispatch("cartProductList", guestQuoteId);
        }
      } catch (err) {
        commit("cartProductsProgress", false);
        throw err;
      }
    },
    updateToCConectionCart: async ({ commit }, data) => {
      try {
        commit("cartProductsProgress", true);
        const response = await doPutCConectionCartItem(
          data.sku,
          data.qty,
          data.ccid
        );
        commit("cartProductsProgress", false);
        return response;
      } catch (err) {
        commit("cartProductsProgress", false);
        throw err;
      }
    },
    updateToConfiguredCart: async ({ commit, dispatch }, data) => {
      let guestQuoteId = getCookieByName(crGuestQuoteId);
      try {
        commit("cartProductsProgress", true);
        const cartProduct = await doPutConfiguredCartItem(
          data.sku,
          data.qty,
          guestQuoteId,
          data.itemId,
          data.optionId,
          data.optionValue
        );
        if (cartProduct) {
          commit("cartProductsProgress", false);
          //commit('setCartDrawer', true);
          commit("setCartProduct", cartProduct);
          await dispatch("cartProductList", guestQuoteId);
          await dispatch("productTotalAmt", guestQuoteId);
        }
      } catch (err) {
        await dispatch("cartProductList", guestQuoteId);
        await dispatch("productTotalAmt", guestQuoteId);
        commit("cartProductsProgress", false);
        throw err;
      }
    },
    updateToGiftCart: async ({ commit, dispatch, state }, data) => {
      let guestQuoteId = getCookieByName(crGuestQuoteId);

      try {
        commit("inProgress", true);
        const cartProduct = await doPutGiftCartItem(
          data,
          guestQuoteId,
          state.giftCartDetailsGuest
        );
        if (cartProduct) {
          commit("inProgress", false);
          if (data.routeName != "CartProducts") {
            //  commit('setCartDrawer', true);
          }
          commit("setCartProduct", cartProduct);
          dispatch("cartProductList", guestQuoteId);
          dispatch("productTotalAmt", guestQuoteId);
        }
      } catch (err) {
        dispatch("cartProductList", guestQuoteId);
        dispatch("productTotalAmt", guestQuoteId);
        commit("inProgress", false);
        throw err;
      }
    },
    updateMiniCart: async ({ commit, dispatch }, data) => {
      let guestQuoteId = getCookieByName(crGuestQuoteId);
      try {
        commit("cartProductsProgress", true);
        const cartProduct = await doPutMiniCartItem(
          data.sku,
          data.qty,
          guestQuoteId,
          data.itemId,
          data.ccid,
          data.price,
          data.personalizeDetails,
          data.optionId,
          data.optionValue
        );
        if (cartProduct) {
          commit("cartProductsProgress", false);
          // commit('setCartDrawer', true);
          commit("setCartProduct", cartProduct);
          await dispatch("cartProductList", guestQuoteId);
          await dispatch("productTotalAmt", guestQuoteId);
          return cartProduct;
        }
      } catch (err) {
        await dispatch("cartProductList", guestQuoteId);
        await dispatch("productTotalAmt", guestQuoteId);
        commit("cartProductsProgress", false);
        throw err;
      }
    },
    updateToCart: async ({ commit, dispatch }, data) => {
      let guestQuoteId = getCookieByName(crGuestQuoteId);
      try {
        commit("inProgress", true);
        const cartProduct = await doPutCartItem(
          data.sku,
          data.qty,
          guestQuoteId,
          data.price,
          data.itemId,
          data.personalizedData
        );
        if (cartProduct) {
          commit("inProgress", false);
          // commit('setCartDrawer', true);
          commit("setCartProduct", cartProduct);
          dispatch("cartProductList", guestQuoteId);
          dispatch("productTotalAmt", guestQuoteId);
        }
      } catch (err) {
        await dispatch("cartProductList", guestQuoteId);
        await dispatch("productTotalAmt", guestQuoteId);
        commit("inProgress", false);
        throw err;
      }
    },
    updateSessionProductToCart: async (
      { commit, dispatch },
      { sku, qty, price, itemId, productOption }
    ) => {
      let guestQuoteId = getCookieByName(crGuestQuoteId);
      try {
        commit("inProgress", true);
        const cartProduct = await doPutSessionCartItem({
          sku,
          qty,
          guestQuoteId,
          price,
          itemId,
          productOption,
        });
        if (cartProduct) {
          commit("inProgress", false);
          // commit('setCartDrawer', true);
          commit("setCartProduct", cartProduct);
          dispatch("cartProductList", guestQuoteId);
          dispatch("productTotalAmt", guestQuoteId);

          return humps(cartProduct);
        }
      } catch (err) {
        await dispatch("cartProductList", guestQuoteId);
        await dispatch("productTotalAmt", guestQuoteId);
        commit("inProgress", false);
        throw err;
      }
    },

    cartDrawer: async ({ commit }, flag) => {
      commit("setCartDrawer", flag);
    },
  },
};
