import { getField, updateField } from "vuex-map-fields";

export const productList = {
  namespaced: true,
  state: {
    inProgress: true,
    monograms: [
      {
        name: "historical",
        img: "@/assets/c34472485c672568a5ab8b9af7f932a9e28f6a06.png",
      },
      {
        name: "historical1",
        img: "@/assets/4de16d9079eb1077437b064167cc1e8b4a90ba11.png",
      },
      { name: "historical2", img: "" },
      { name: "historical3", img: "" },
    ],
    fontList: [
      {
        name: "palmer",
        img: "@/assets/c34472485c672568a5ab8b9af7f932a9e28f6a06.png",
        description:
          "Times New Roman is a serif typeface designed for use in body text. It was commissioned by the British newspaper",
      },
      {
        name: "helvetica-LT",
        img: "@/assets/c34472485c672568a5ab8b9af7f932a9e28f6a06.png",
        description:
          "Helvetica or Neue Haas Grotesk is a widely used sans-serif typeface developed in 1957 by Swiss typeface designer Max Miedinger with input from Eduard Hoffmann",
      },
      {
        name: "mozart",
        img: "@/assets/c34472485c672568a5ab8b9af7f932a9e28f6a06.png",
        description:
          "Whyte is a sans-serif type family designed by Johannes Breyer, Fabian Harb and Erkin Karamemet. It was published through Swiss foundry Dinamo in 2019",
      },
    ],
    paperWeightList: [
      {
        text: "100# Cover",
        callback: () => "Light weight 100# Cover (100gsm)",
        value: 2,
      },
      {
        text: "50# Cover",
        callback: () => "Heavy weight 50# Cover (50gsm)",
        value: 5,
      },
      {
        text: "10# Cover",
        callback: () => "Light weight 10# Cover (10gsm)",
        value: 10,
      },
    ],
    papersList: [
      {
        text: "CRANE LETTERS",
        img: "@/assets/c34472485c672568a5ab8b9af7f932a9e28f6a06.png",
        description:
          "America's original luxury 100% cotton paper unique to Crane and made in the USA, Soft to the touch and rich in texture",
      },
      {
        text: "SUPERFINE",
        img: "@/assets/4de16d9079eb1077437b064167cc1e8b4a90ba11.png",
        description:
          "America's original luxury 100% cotton paper unique to Crane and made in the USA, Soft to the touch and rich in texture",
      },
      {
        text: "HEMP",
        img: "@/assets/5bbf1de2fb2677c5376e87a826d9fd89876e22a3.png",
        description:
          "New to the crane family our 50% Hemp and 50% Wood fiber paper is exclusive to crane ",
      },
    ],
    papersTypeList: [
      { text: "SMOOTH", callback: () => "SMOOTH" },
      { text: "TEXTURED", callback: () => "TEXTURED" },
      { text: "LUXE", callback: () => "LUXE" },
    ],
    fontSizeList: [
      {
        text: "50PT",
        callback: () => "50PT",
        value: 50,
      },
      {
        text: "70PT",
        callback: () => "70PT",
        value: 70,
      },
      {
        text: "80PT",
        callback: () => "24PT",
        value: 80,
      },
    ],
    printMethodsList: [
      { text: "TRANSFER", callback: () => "TRANSFER" },
      { text: "ENGRAVING", callback: () => "ENGRAVING" },
      { text: "EMBOSSING", callback: () => "EMBOSSING" },
    ],
    printQuantityList: [
      { text: "60 Sets", callback: () => "60 Sets" },
      { text: "90 Sets", callback: () => "90 Sets" },
      { text: "110 Sets", callback: () => "110 Sets" },
    ],
    textAlignmentList: [
      {
        text: "left",
        callback: () => "left",
      },
      {
        text: "center",
        callback: () => "center",
      },
      {
        text: "right",
        callback: () => "right",
      },
      {
        text: "justify",
        callback: () => "justify",
      },
    ],
    textareaMaxlineList: [
      {
        callback: () => 1,
        text: 1,
      },
      {
        callback: () => 2,
        text: 2,
      },
      {
        callback: () => 3,
        text: 3,
      },
      {
        callback: () => 4,
        text: 4,
      },
      {
        callback: () => 5,
        text: 5,
      },
      {
        callback: () => 6,
        text: 6,
      },
      {
        callback: () => 7,
        text: 7,
      },
      {
        callback: () => 8,
        text: 8,
      },
      {
        callback: () => 9,
        text: 9,
      },
      {
        callback: () => 10,
        text: 10,
      },
    ],
    typesMotif: [
      {
        text: "MOTIF",
        description:
          "Times New Roman is a serif typeface designed for use in body text. It was commissioned by the British newspaper",
        value: "MOTIF",
      },
    ],
    typesMonogram: [
      {
        text: "MONOGRAM",
        description:
          "Times New Roman is a serif typeface designed for use in body text. It was commissioned by the British newspaper",
        value: "MONOGRAM",
      },
    ],
    designs: [
      { name: "heart", img: "@/assets/heart.png" },
      { name: "heart1", img: "" },
      { name: "heart2", img: "" },
      { name: "heart3", img: "" },
    ],
    patterns: [
      "executive",
      "executive1",
      "executive2",
      "executive3",
      "executive4",
      "executive5",
      "executive6",
      "executive7",
    ],
    library: [
      "jan photo new yourk",
      "feb photo new yourk",
      "march photo new yourk",
      "april photo new yourk",
      "may photo new yourk",
      "june photo new yourk",
      "july photo new yourk",
      "dec photo new yourk",
    ],
    initialsLetters: [
      "@/assets/c34472485c672568a5ab8b9af7f932a9e28f6a06.png",
      "@/assets/c34472485c672568a5ab8b9af7f932a9e28f6a06.png",
      "@/assets/c34472485c672568a5ab8b9af7f932a9e28f6a06.png",
      "@/assets/c34472485c672568a5ab8b9af7f932a9e28f6a06.png",
    ],
    userMessage: "wishing you joy this season and always. love rebecca & corey",
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,

    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo;
    },
  },
  actions: {},
};
